import { combineReducers, configureStore } from '@reduxjs/toolkit';
import loginReducer from './slices/logindetailsslice';

const rootReducer = combineReducers({
  loginDetails: loginReducer,
  // Add other reducers here if needed
});

const loadState = () => {
  try {
    const serializedState = localStorage.getItem('reduxState');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    console.error('Error loading state from localStorage:', err);
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('reduxState', serializedState);
  } catch (err) {
    console.error('Error saving state to localStorage:', err);
    // Handle errors here, e.g., notify the user or log the error
  }
};

const persistedState = loadState();

export const store = configureStore({
  reducer: rootReducer,
  preloadedState: persistedState,
  // Add middleware and enhancers as needed
});

store.subscribe(() => {
  saveState(store.getState());
});
