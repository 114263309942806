import React, { useEffect, useState } from "react";
import Sidebars from "../components/Sidebars";
import { Controller, useForm } from "react-hook-form";
import InputField from "../components/InputField";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { viewSubTicketGetMethod } from "../globalApi/Api";
import axios from "axios";

export default function EditCombo() {
  const { state } = useLocation();
  const navigate = useNavigate()
  const { control, handleSubmit, setValue, reset,watch } = useForm();
  const [comboSelect, setComboSelect] = useState([]);

  const { selectcombo } = watch();
  const comboChange = comboSelect.join(', ')
console.log("comboChange",comboChange);

  useEffect(() => {
      setComboSelect([...comboSelect, selectcombo]);
  }, [selectcombo]);

  const update = useMutation(
    (postData) =>
      axios.put(`https://api.2kvirtualworld.com/ticketBook/addcombo/${state?.id}`,postData
      ),
    {
      onSuccess: () => {
        // alert("Update successful!");
        navigate('/viewCombo')
        reset();
      },
      onError: (error) => {
        console.error("Mutation failed:", error);
      },
    }
  );
  const { data, isLoading, isError, isRefetching } = useQuery(
    "viewSubTicket",
    viewSubTicketGetMethod
  );

  const Submit = (data) => {
    console.log("Data", data);
    const value ={
        comboname: data?.comboname,
        selectcombo:  state?.selectcombo,
        actualprice: data?.actualPrice,
        discountprice:data?.discountPrice,
        totalprice: data?.totalPrice,
        description: data?.description,
    }
    update.mutate(value)
    console.log('value',value);
    // alert("Data added successfully..");
  };
  
  // console.log("start", state);
  useEffect(() => {
    setValue("comboname", state?.comboname);
    setValue("discountPrice", state?.discountprice);
    setValue("actualPrice", state?.actualprice);
    setValue("totalPrice", state?.totalprice);
    setValue("description", state?.description);
    // setValue('category',state?.category)
  }, []);

  return (
    <>
      <section className="container-fluid ps-0 addTicket_mainContainer">
        <div className="container my-2">
          <div className="row">
            <header className="col-lg-11 mx-5 px-0 bg-light addTicket_innerContainer_header">
              <div className=" p-4 addTicket_headed_text ">EDIT COMBO</div>
            </header>
            <div className="col-lg-11 mx-5 py-2 px-5  addTicket_inputField_container ">
              <form
                style={{ padding: "0px 100px" }}
                onSubmit={handleSubmit(Submit)}
              >
                <div className=" d-flex py-3 align-items-center gap-3">
                  <label className="col-3 editComboLabel">
                    Enter a Combo Name
                  </label>
                  <Controller
                    control={control}
                    name="comboname"
                    render={(field) => (
                      <InputField
                        comboInputField="editCombo_selectCombo"
                        {...field}
                        type="text"
                      />
                    )}
                  />
                </div>
                <div className="d-flex py-3 align-items-center gap-3">
                  <div className="col-3 editComboLabel">Select Combo</div>
                  <Controller
                    name="category"
                    control={control}
                    rules={{ required: 'Category is required' }}
                    render={({ field }) => (
                      <select
                        {...field}
                        id="category"
                        className="editCombo_selectCombo"
                      >
                        <option value="">--SELECT COMBO--</option>
                        <option value="TICKET_NAME_1">TICKET_NAME_1</option>
                        <option value="TICKET_NAME_2">TICKET_NAME_2</option>
                        <option value="TICKET_NAME_3">TICKET_NAME_3</option>
                      </select>
                    )}
                  />
                </div>
                <div className=" my-2 p-3 editComboSelectBox">
                  {state?.selectcombo}
                </div>
                <div className=" d-flex py-3 align-items-center gap-3">
                  <label className="col-3 editComboLabel">Description</label>
                  <Controller
                    control={control}
                    name="description"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <textarea
                        style={{
                          width: "80%",
                          padding: "10px",
                          height: "100px",
                        }}
                        value={value}
                        onChange={onChange}
                        className="editCombo_selectCombo"
                      ></textarea>
                    )}
                  />
                </div>
                <div className="col-12  pb-2">
                  <div className="d-flex justify-content-end pb-3 ">
                    <label className="col-2 editComboLabel">Actual Price</label>
                    <Controller
                      control={control}
                      name="actualPrice"
                      render={(field) => (
                        <InputField
                          comboPriceInputField="editComboPriceInputField"
                          {...field}
                          type="number"
                        />
                      )}
                    />
                  </div>
                  <div className="d-flex justify-content-end pb-3">
                    <label className="col-2 editComboLabel">
                      Discount Price
                    </label>
                    <Controller
                      control={control}
                      name="discountPrice"
                      render={(field) => (
                        <InputField
                          comboPriceInputField="editComboPriceInputField"
                          {...field}
                          type="number"
                        />
                      )}
                    />
                  </div>
                  <div className="d-flex justify-content-end pb-3">
                    <label className="col-2 editComboLabel">Total Price</label>
                    <Controller
                      control={control}
                      name="totalPrice"
                      render={(field) => (
                        <InputField
                          comboPriceInputField="editComboPriceInputField"
                          {...field}
                          type="number"
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-end pb-3">
                  <button className="editCombo_submitBtn" type="submit">
                    {" "}
                    SUBMIT
                  </button>
                </div>
              </form>
            </div>
          </div>
          {/* <PopUpModal isOpen={addTicketPopUp === null}>
        <div className="popup-overlay">
          <div className="confirmation-popup">
            <div style={{ height: "120px" }}>
              <img
                src="/assets/edit_back.jpg"
                alt="no-image"
                className="p-0 m-0 popUpBackGround"
              ></img>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <span className="popUpContent ">Thank you!</span>
              <span className="popUpContentPara py-3">
                Your ticket has been added successfully
              </span>
              <button
                onClick={() => {
                  setAddTicketPopUp(null);
                }}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </PopUpModal> */}
        </div>
      </section>
    </>
  )
}
