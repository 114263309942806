import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import InputField from './InputField'
import { useNavigate } from 'react-router-dom'
import ReactDatePicker from 'react-datepicker'
import { useLocation } from 'react-router-dom'

const BMEditComponents = (props) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm()

  const navigate = useNavigate()
  const { state } = useLocation()

  const [startDate, setStartDate] = useState()
  // console.log("bookingdetails",props?.bookingdetails)
  useEffect(() => {
    setValue('customerName', props?.bookingdetails?.customername)
    setValue('location', props?.bookingdetails?.ticketname)
    setValue('adultCount', props?.bookingdetails?.adulttickets)
    setValue('childCount', props?.bookingdetails?.kidstickets)
    setValue('date', props?.bookingdetails?.date)
    setValue('slot', props?.bookingdetails?.slot)
  }, [])
  return (
    <>
      <div className="col-lg-12 m-0 p-0">
        <div style={{ height: '270px' }}>
          <img
            src="/assets/edit_back.jpg"
            alt="no-image"
            className="p-0 m-0 popUpBackGround"
          ></img>
        </div>
      </div>
      <div className="col-lg-12 --model_label">
        <div className="col-3">
          <label className="bookingeditlabel">Customer Name</label>
        </div>
        <div className="col-9">
          <Controller
            control={control}
            name="customerName"
            render={(field) => (
              <InputField
                bookingEditInput="bookingEditInput"
                {...field}
                type="text"
              />
            )}
          />
        </div>
      </div>
      <div className="col-lg-12 --model_label">
        <div className="col-3">
          <label className="bookingeditlabel ">Location</label>
        </div>
        <div className="col-9">
          <Controller
            control={control}
            name="location"
            render={(field) => (
              <InputField
                bookingEditInput=" bookingEditInput"
                {...field}
                type="text"
              />
            )}
          />
        </div>
      </div>
      <div className="col-lg-12 --model_label">
        <div className="col-3">
          <label className="bookingeditlabel">Adult Count</label>
        </div>
        <div className="col-3">
          <Controller
            control={control}
            name="adultCount"
            render={(field) => (
              <InputField
                bookingEditInput=" bookingEditInput"
                {...field}
                type="number"
              />
            )}
          />
        </div>
        <div className="col-3 px-3">
          <label className="bookingeditlabel">Child Count</label>
        </div>
        <div className="col-3">
          <Controller
            control={control}
            name="childCount"
            render={(field) => (
              <InputField
                bookingEditInput=" bookingEditInput"
                {...field}
                type="number"
              />
            )}
          />
        </div>
      </div>
      <div className="col-lg-12 --model_label">
        <div className="col-3">
          <label className="bookingeditlabel">Date</label>
        </div>
        <div className="col-3">
          <Controller
            control={control}
            name="date"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <ReactDatePicker
                className=" bookingEditInput"
                value={value}
                dateFormat="dd/MM/yyyy"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
            )}
          />
        </div>
        <div className="col-3 px-3">
          <label className="bookingeditlabel">Slot</label>
        </div>
        <div className="col-3">
          <Controller
            control={control}
            name="slot"
            render={(field) => (
              <InputField
                bookingEditInput="bookingEditInput"
                {...field}
                type="time"
              />
            )}
          />
        </div>
      </div>
      <div className="col-lg-12 ---model_label">
        <div className="col-lg-3"></div>
        <div className="col-lg-9 d-flex justify-content-around">
          <div
            className="edit_icons "
            onClick={() => {
              navigate('/bookingManagementEditTicket', {
                state: props?.bookingdetails,
              })
            }}
          >
            <span className="edit_ticket_btn">EDIT</span>
            <img
              src="/assets/edit.png"
              className="img-fluid edit_ticket_image"
              alt="no-image"
            />
          </div>
          <div
            className="edit_icons"
            onClick={() => props?.setShowConfirmation(true)}
          >
            <span className="edit_ticket_btn">DELETE</span>
            <img
              src="/assets/delete.svg"
              alt="no-image"
              className="img-fluid delete_ticket_image"
            />
          </div>
          <div
            className="edit_icons"
            onClick={() => {
              navigate('/bookingTable')
            }}
          >
            <span className="edit_ticket_btn">BACK</span>
            <img
              src="/assets/back_icon.png"
              className="img-fluid edit_ticket_image"
              alt="no-image"
            />
          </div>
        </div>
      </div>
    </>
  )
}
export default BMEditComponents
