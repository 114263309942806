import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Sidebars from '../components/Sidebars'
import { Controller, useForm } from 'react-hook-form'
import InputField from '../components/InputField'
import BMEditComponents from '../components/BMEditComponents'
import PopUpModal from '../components/PopUpModal'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
const BMEditPage = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm()
  const navigate = useNavigate()
  const { state } = useLocation()
  let bookingdetails = state
  // console.log("bookingdetails",bookingdetails)

  const [showConfirmation, setShowConfirmation] = useState(null)
  const [deletePopUp, setDeletePopUp] = useState(null)

  const handleDelete = async (state) => {
    // Perform delete action here
    // For example, call a function to delete the item
    // ...
    // After deletion, you can close the confirmation popup
    // setShowConfirmation(null);
    console.log('state', state)
    try {
      await axios.delete(
        `https://api.2kvirtualworld.com/booktickets/bookings/${state?.id}`,
      )
    } catch (error) {
      console.log('Error', error)
    }
    setDeletePopUp(1)
  }

  return (
    <>
      <section className="main_Section d-flex justify-content-center">
        {/* <Sidebars /> */}
        <div className="container  px-5 py-3  ">
          <div className="row booking_form_block ">
            <BMEditComponents
              bookingdetails={bookingdetails}
              setShowConfirmation={setShowConfirmation}
              setDeletePopUp={setDeletePopUp}
            />
          </div>
        </div>
      </section>
      <PopUpModal isOpen={showConfirmation === null}>
        <div style={{ height: '130px' }}>
          <img
            src="/assets/edit_back.jpg"
            alt="no-image"
            className="p-0 m-0 popUpBackGround"
          ></img>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center ">
          <span className="popUpContent ">
            Are you sure you want to delete?
          </span>
          <div className="d-flex justify-content-center gap-3 pt-4">
            <button
              onClick={() => {
                handleDelete(state)
              }}
            >
              Yes
            </button>
            <button onClick={() => setShowConfirmation(false)}>No</button>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal isOpen={deletePopUp === null}>
        <div style={{ height: '130px' }}>
          <img
            src="/assets/edit_back.jpg"
            alt="no-image"
            className="p-0 m-0 popUpBackGround"
          ></img>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center ">
          <span className="popUpContent ">Thank you!</span>
          <span className="popUpContentPara py-3">
            Your ticket has been deleted successfully
          </span>

          <button
            onClick={() => {
              setDeletePopUp(null)
              navigate('/bookingTable')
            }}
          >
            OK
          </button>
        </div>
      </PopUpModal>
    </>
  )
}

export default BMEditPage
