import React from "react";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import AppRoutes from "./routes";
import Example from "./pages/Example";
import BookingTable from "./pages/BookingTable";

const App = () => {
  const queryClient = new QueryClient();

  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <AppRoutes />
          </QueryClientProvider>
        </BrowserRouter>
      </Provider>
      {/* <Example/> */}
    </>
  );
};

export default App;
