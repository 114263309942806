import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import InputField from './InputField'
import { useLocation, useNavigate } from 'react-router-dom'
import OtpEnter from './OtpEnter'
import { useQuery } from 'react-query'
import { getOTP } from '../globalApi/Api'
import { useSelector } from 'react-redux'
import { selectUserByEmail } from '../redux/slices/logindetailsslice'

const GetOtp = () => {
  const { control, handleSubmit } = useForm()
  const navigate = useNavigate()
  const { state } = useLocation()
  const [emailError, setEmailError] = useState(false)
  const [loginUser, setLoginUser] = useState('')
  const [loading, setLoading] = useState(false) // Loading state
  const users = useSelector((state) => state.loginDetails.users)

  console.log('users', users)

  const user = useSelector((state) => selectUserByEmail(state, loginUser))
  console.log('user', user?.emailid)
  sessionStorage.setItem('resetEmail', user?.emailid)

  // let loginemail = localStorage.getItem('emailId')
  // console.log('loginemail', loginemail)

  const onSubmit = (data) => {
    const enteredEmail = data.emailid.trim().toLowerCase()
    setLoginUser(enteredEmail)
  }
  useEffect(() => {
    if (!loginUser || loading) return

    setLoading(true)
    if (user && user.emailid.toLowerCase() === loginUser) {
      setLoading(false)
      navigate('/otpenter')
    } else {
      setLoading(false)
      setEmailError(true)
    }
  }, [user, loginUser, loading, navigate])
  return (
    <>
      <section className="main_Section">
        <div className="container ">
          <div className="row  vh-100 ">
            <div className="col-lg-12 mx-5 d-flex  flex-column justify-content-center">
              <div className="otp_form_block">
                <div className="treat_logo my-2">
                  <img
                    src="/assets/2k_logo.png"
                    alt=""
                    style={{ width: '100%', height: '100%' }}
                  />
                </div>

                <form className="form_block" onSubmit={handleSubmit(onSubmit)}>
                  <div style={{ width: '100%' }}>
                    <Controller
                      control={control}
                      name="emailid"
                      render={(field) => (
                        <InputField
                          {...field}
                          loginInputCss="login_input_field"
                          placeholder="Enter Your Email Id"
                          type="email"
                        />
                      )}
                      rules={{ required: true }}
                    />
                  </div>
                  {emailError && (
                    <>
                      <div style={{ width: '100%' }}>
                        <p style={{ color: 'red' }}>Invalid emailId</p>
                      </div>
                    </>
                  )}
                  <div className="text-center" style={{ width: '100%' }}>
                    <button className="otp_button my-4">Get OTP</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default GetOtp
