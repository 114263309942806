import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

// import LoginInputField from "../components/LoginInputField";
import InputField from '../components/InputField'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useMutation } from 'react-query'
// import treat_logo from "../../public/assets/2k_logo.png";
import { login as loginreducer } from '../redux/slices/logindetailsslice'
import { useDispatch, useSelector } from 'react-redux'

const Login = () => {
  const { control, handleSubmit } = useForm()
  const [type, setType] = useState('password')
  const [error, setError] = useState('')
  const navigate = useNavigate()
const loginDetails = useSelector((state) => state.loginDetails?.addUsers)

  console.log('loginDetails', loginDetails)
  const passwordType = () => {
    if (type === 'password') {
      setType('text')
    } else {
      setType('password')
    }
  }
  const login = async ({ email, password }) => {
    try {
      const response = await axios.post(
        `https://api.2kvirtualworld.com/ticketBook/addusers/login?emailid=${email}&password=${password}`,
        { email, password },
      )
      return response.data // Assuming the API returns some user data or token
    } catch (error) {
      throw new Error(error.response.data.message) // Throw error if login fails
    }
  }

  const { mutate, isError, isLoading } = useMutation(login, {
    onSuccess: (userData) => {
      sessionStorage.setItem('login', true)
      localStorage.setItem("emailId",userData?.emailid)
      // console.log('usserData', userData)
      navigate('/dashboard')
      window.location.reload()
    },
    onError: (error) => {
      setError("Invalid username and password")
      // console.error('Login error message:', error)
    },
  })
  const onsubmit = async (formData) => {
  await  mutate(formData)
  }
  return (
    <>
      <section className="main_Section">
        <div className="container">
          <div className="row  vh-100">
            <div className="col-lg-12 mx-5 d-flex  flex-column justify-content-center">
              <div className="login_form_block">
                <div className="treat_logo my-2">
                  <img
                    src="/assets/2k_logo.png"
                    alt=""
                    style={{ width: '100%', height: '100%' }}
                  />
                </div>

                <form className="form_block" onSubmit={handleSubmit(onsubmit)}>
                  <div style={{ width: '100%' }}>
                    <Controller
                      control={control}
                      name="email"
                      render={(field) => (
                        <InputField
                          {...field}
                          loginInputCss="login_input_field"
                          placeholder="Username"
                          type="email"
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>

                  <div style={{ width: '100%' }}>
                    <Controller
                      control={control}
                      name="password"
                      render={(field) => (
                        <InputField
                          loginInputCss="login_input_field"
                          {...field}
                          placeholder="password"
                          type={type}
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                  <div
                    onClick={passwordType}
                    className="d-flex align-items-center gap-2"
                    style={{ fontSize: '18px', width: '100%' }}>
                    <input
                      type="checkBox"
                      checked={type == 'text' ? 'true' : ''}
                      readOnly
                    />{' '}
                    Show Password
                  </div>

                  <div style={{ width: '100%' }}>
                    {' '}
                    {isError && (
                      <p style={{color:"red",fontWeight:"600"}}>
                        Invalid username and password
                      </p>
                    )}{' '}
                  </div>
                  <div style={{ width: '100%' }}>
                    <p
                      className="text-end login_para"
                      onClick={() => {
                        navigate('/getotp')
                      }}>
                      Forgot password?
                    </p>
                  </div>

                  <div
                    style={{ width: '100%' }}
                    className="d-flex justify-content-center">
                    <button className="login_button">
                      {isLoading ? (
                        <i className="fa fa-spinner fa-spin"></i>
                      ) : (
                        'Login'
                      )}
                    </button>
                  </div>
                  <div style={{ width: '100%' }}>
                    {isError && (
                      <p style={{ color: 'red' }}>
                        {error &&
                          error?.response &&
                          error?.response?.data &&
                          error?.response?.data?.message}
                      </p>
                    )}{' '}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default Login
