import React, { useEffect, useState } from 'react'
import Sidebars from '../components/Sidebars'
import { Controller, useForm } from 'react-hook-form'
import InputField from '../components/InputField'
import { useNavigate } from 'react-router-dom'
import TicketSlotModal from '../components/TicketSlotModal'
import PopUpModal from '../components/PopUpModal'
import { useLocation } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import moment from 'moment/moment'
import axios from 'axios'

const EditPage = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm()
  const navigate = useNavigate()
  const [base64Image, setBase64Image] = useState(null)
  const [startDate, setStartDate] = useState('')
  const [showConfirmation, setShowConfirmation] = useState(null)
  const [ticketCategory, setTicketCategory] = useState('adult')
  const { state } = useLocation()

  const [deletePopUp, setDeletePopUp] = useState(null)

  const handleDelete = async (state) => {
    // Perform delete action here
    // For example, call a function to delete the item
    // ...
    // After deletion, you can close the confirmation popup
    // setShowConfirmation(null);
    console.log('state', state)
    try {
      await axios.delete(
        `https://api.2kvirtualworld.com/ticketBook/addtickets/${state?.id}`,
      )
    } catch (error) {
      console.log('Error', error)
    }
    setDeletePopUp(1)
  }

  const handleImageInputChange = (event) => {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        const base64String = reader.result
        const base64LetterRemove = base64String.substring(
          base64String.indexOf(',') + 1,
        )
        setBase64Image(base64LetterRemove)
      }
      reader.onerror = (error) => {
        console.error('Error occurred while converting image to base64:', error)
      }
    }
  }
  let val = state
  console.log('val', state)
  useEffect(() => {
    setValue('ticketName', state?.ticketname)
    setValue('ticketAmount', state?.ticketamount)
    setValue('ticketDate', state?.ticketDate)
    setValue('ticketValidity', state?.ticketvalidity)
    // setValue('uploadImage', state?.uploadimage)
    setValue('ticketDescription', state?.ticketdescription)
    setValue('category', ticketCategory)
  }, [])
  return (
    <>
      <section className=" main_Section d-flex">
        {/* <Sidebars /> */}
        <div className="container px-5 p-2">
          <div className="row edit_page_form_block  ">
            <div className="col-lg-12 p-0 m-0">
              <div
                style={{
                  width: '100%',
                  height: '200px',
                }}>
                <img
                  src="/assets/edit_back.jpg"
                  style={{
                    width: '100%',
                    height: '100%',
                    borderRadius: '15px 15px 0px 0px',
                  }}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-12 editPageInputBlock">
              <div className="col-3">
                <label className="addsubticket_label">Ticket Name</label>
              </div>
              <div className="col-6">
                <Controller
                  control={control}
                  name="ticketName"
                  render={(field) => (
                    <InputField
                      editPageInputField="editPageInputField"
                      {...field}
                      type="text"
                    />
                  )}
                  rules={{ required: true }}
                />
              </div>
            </div>
            <div className="col-lg-12  editPageInputBlock ">
              <div className="col-5"></div>
              <div className="col-6 d-flex justify-content-center align-items-center">
                <Controller
                  control={control}
                  name="category"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <input
                      onClick={() => {
                        setTicketCategory('adult')
                      }}
                      type="checkBox"
                      checked={ticketCategory === 'adult'}
                      className="--addTicket_checkBox_inputFields"
                      onChange={onChange}
                      defaultValue={false}
                    />
                  )}
                  // rules={{ required: true }}
                />
                <label>Adult Ticket</label>
                <Controller
                  control={control}
                  name="category"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <input
                      onClick={() => {
                        setTicketCategory('kids')
                      }}
                      onChange={onChange}
                      type="checkBox"
                      checked={ticketCategory === 'kids'}
                      className="--addTicket_checkBox_inputFields"
                      defaultValue={false}
                    />
                  )}
                  // rules={{ required: true }}
                />
                <label>Kids Ticket</label>
              </div>
              <div className="col-3 d-flex"></div>
            </div>
            <div className="col-lg-12 editPageInputBlock">
              <div className="col-3">
                {' '}
                <label className="addsubticket_label">Ticket Amount</label>
              </div>
              <div className="col-6">
                <Controller
                  control={control}
                  name="ticketAmount"
                  render={(field) => (
                    <InputField
                      editPageInputField="editPageInputField"
                      {...field}
                      type="number"
                    />
                  )}
                  rules={{ required: true }}
                />
              </div>
            </div>
            <div className="col-lg-12 editPageInputBlock">
              <div className="col-3">
                <label className="addsubticket_label">Ticket Date</label>
              </div>
              <div className="col-6">
                <Controller
                  control={control}
                  name="ticketDate"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <DatePicker
                      className="editPageInputField"
                      value={value}
                      dateFormat={'yyyy-MM-dd'}
                      minDate={moment().toDate()}
                      maxDate={moment().toDate()}
                      startDate={moment().toDate()}
                      endDate={startDate}
                      selected={value}
                      onChange={(date) => onChange(date)}
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-lg-12 editPageInputBlock">
              <div className="col-3">
                <label className="addsubticket_label">Ticket Validity</label>
              </div>
              <div className="col-6">
              <Controller
                    control={control}
                    name="ticketValidity"
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        className="editPageInputField"
                        selected={value ? new Date(value) : null}
                        onChange={(date) => onChange(date)}
                        dateFormat="yyyy-MM-dd"
                        minDate={moment().toDate()}
                        maxDate={moment().add(1, 'year').toDate()} // Example of setting a max date
                        showTimeInput={false} // Ensure time input is hidden
                      />
                    )}
                  />
              </div>
            </div>
            {/* <div className="col-lg-12 editPageInputBlock">
              <div className="col-3">
                <label className="addsubticket_label">Upload Image</label>
              </div>
              <div className="col-6 addTicket_uploadImage_inputFields">
               
                <Controller
                  control={control}
                  name="uploadImage"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <input
                      className="addTicket_uploadImage_input"
                      accept="image/*"
                      type="file"
                      onChange={(e) => onChange(handleImageInputChange(e))}
                    />
                  )}
                />
                <img
                  style={{
                    height: "25px",
                    width: "25px",
                    borderRadius: "6px",
                  }}
                  src="/assets/addImage.png"
                  alt="addImage"
                />
              </div>
            </div> */}
            <div className="col-lg-12 editPageInputBlock py-3">
              <div className="col-3">
                <label className="addsubticket_label">Ticket Description</label>
              </div>
              <div className="col-6">
                <Controller
                  control={control}
                  name="ticketDescription"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    // <InputField
                    //   addTicketDescriptionInputCss="--addTicket_description_inputFields"
                    //   {...field}
                    //   type="text"
                    // />
                    <textarea
                      value={value}
                      onChange={onChange}
                      className="addSubTicketTextField"></textarea>
                  )}
                  // rules={{ required: true }}
                />
              </div>
            </div>
            <div className="col-lg-12 d-flex justify-content-center py-3">
              <div className="col-3"></div>
              <div className="col-6 d-flex justify-content-between">
                <div
                  className="edit_icons"
                  onClick={() => {
                    navigate('/editTicket', { state: val })
                  }}>
                  <span className="edit_ticket_btn">EDIT</span>
                  <img
                    src="/assets/edit.png"
                    className="img-fluid edit_ticket_image"
                    alt="no-image"
                  />
                </div>
                <div
                  className="edit_icons"
                  onClick={() => setShowConfirmation(1)}>
                  <span className="edit_ticket_btn">DELETE</span>
                  <img
                    src="/assets/delete.svg"
                    className="img-fluid edit_ticket_image"
                    alt="no-image"
                  />
                </div>
                <div
                  className="edit_icons"
                  onClick={() => {
                    navigate('/viewSubTicket')
                  }}>
                  <span className="edit_ticket_btn">BACK</span>
                  <img
                    src="/assets/delete.png"
                    className="img-fluid edit_ticket_image"
                    alt="no-image"
                  />
                </div>
              </div>
            </div>
          </div>

          <PopUpModal isOpen={showConfirmation === null}>
            <div style={{ height: '130px' }}>
              <img
                src="/assets/edit_back.jpg"
                alt="no-image"
                className="p-0 m-0 popUpBackGround"></img>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center ">
              <span className="popUpContent ">
                Are you sure you want to delete?
              </span>
              <div className="d-flex justify-content-center gap-3 pt-4">
                <button
                  onClick={() => {
                    handleDelete(state)
                  }}>
                  Yes
                </button>
                <button onClick={() => setShowConfirmation(false)}>No</button>
              </div>
            </div>
          </PopUpModal>
          <PopUpModal isOpen={deletePopUp === null}>
            <div style={{ height: '130px' }}>
              <img
                src="/assets/edit_back.jpg"
                alt="no-image"
                className="p-0 m-0 popUpBackGround"></img>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center ">
              <span className="popUpContent ">Thank you!</span>
              <span className="popUpContentPara py-3">
                Your ticket has been deleted successfully
              </span>

              <button
                onClick={() => {
                  setDeletePopUp(null)
                  navigate('/ticketView')
                }}>
                OK
              </button>
            </div>
          </PopUpModal>
        </div>
      </section>
    </>
  )
}

export default EditPage
