import React, { useState } from 'react'

const Popup = () => {
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const handleDelete = () => {
    // Perform delete action here
    // For example, call a function to delete the item
    // ...
    // After deletion, you can close the confirmation popup
    setShowConfirmation(false)
    setShowSuccess(true)
  }
  const handleOk = () => {
    setShowSuccess(false)
  }
  return (
    <div>
      <button onClick={() => setShowConfirmation(true)}>Delete</button>
      {showConfirmation && (
        <div className="popup-overlay">
          <div className="confirmation-popup">
            <div className="confirmation-popup-background"></div>
            <p>Are you sure you want to delete?</p>
            <div className="d-flex justify-content-center gap-3">
              <button onClick={handleDelete}>Yes</button>
              <button onClick={() => setShowConfirmation(false)}>No</button>
            </div>
          </div>
        </div>
      )}
      {showSuccess && (
        <div className="popup-overlay">
          <div className="success-popup">
            <div className="confirmation-popup-background"></div>
            <p>Your data has been deleted.</p>
            <div className="d-flex justify-content-center">
              <button onClick={handleOk}>OK</button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default Popup
