import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  users: [],
  // loginUser: null,
}
const logindetailsslice = createSlice({
  name: 'loginDetails',
  initialState,
  reducers: {
    // login(state, action) {
    //   state.loginUser = action.payload
    // },
    addUsers(state, action) {
      console.log('Payload:', action.payload);
      state.users.push(...action.payload)
    },
  },
})

// export const { login } = logindetailsslice.actions
// export const { deleteUser } = logindetailsslice.actions
export const { addUsers } = logindetailsslice.actions
// Selector to find user by emailid
export const selectUserByEmail = (state, email) =>
  state.loginDetails.users.find((user) => {
    return user?.emailid === email
  })

export default logindetailsslice.reducer
