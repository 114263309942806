import React, { useState } from 'react'
import Sidebars from '../components/Sidebars'
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TablePagination,
  Select,
  colors,
} from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

export default function SalesReport() {
  const { control, handleSubmit, watch } = useForm()
  // const [startDate, setStartDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(new Date());
  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange
  const salesReport = [
    {
      date: '1-MAY-2024',
      ticketName: 'ADULT',
      count: 100,
      price: 100000,
    },
    {
      date: '1-MAY-2024',
      ticketName: 'ADULT',
      count: 100,
      price: 100000,
    },
    {
      date: '1-MAY-2024',
      ticketName: 'ADULT',
      count: 100,
      price: 100000,
    },
    {
      date: '1-MAY-2024',
      ticketName: 'ADULT',
      count: 100,
      price: 100000,
    },
    {
      date: '1-MAY-2024',
      ticketName: 'ADULT',
      count: 100,
      price: 100000,
    },
    {
      date: '1-MAY-2024',
      ticketName: 'ADULT',
      count: 100,
      price: 100000,
    },
    {
      date: '1-MAY-2024',
      ticketName: 'ADULT',
      count: 100,
      price: 100000,
    },
    {
      date: '1-MAY-2024',
      ticketName: 'ADULT',
      count: 100,
      price: 100000,
    },
    {
      date: '1-MAY-2024',
      ticketName: 'ADULT',
      count: 100,
      price: 100000,
    },
    {
      date: '1-MAY-2024',
      ticketName: 'ADULT',
      count: 100,
      price: 100000,
    },
    {
      date: '1-MAY-2024',
      ticketName: 'ADULT',
      count: 100,
      price: 100000,
    },
    {
      date: '1-MAY-2024',
      ticketName: 'ADULT',
      count: 100,
      price: 100000,
    },
    {
      date: '1-MAY-2024',
      ticketName: 'ADULT',
      count: 100,
      price: 100000,
    },
    {
      date: '1-MAY-2024',
      ticketName: 'ADULT',
      count: 100,
      price: 100000,
    },
  ]

  const { themePark, datePickerStartToEnd } = watch()
  console.log('hello', themePark, datePickerStartToEnd)

  return (
    <section className="container-fluid ps-0 addTicket_mainContainer">
      {/* <Sidebars /> */}
      <div className="container">
        <div className="row">
          <div className="col-11 mx-5 my-3 px-4 py-3 salesReport_tableField_container">
            <div className="row me-5 d-flex justify-content-end">
              <form className="d-flex justify-content-end">
                <div className="col-2 me-2">
                  <Controller
                    control={control}
                    name="themePark"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        value={value}
                        onChange={onChange}
                        className="salesReport_themePark_select"
                      >
                        <option>None</option>
                        <option value="mgm">MGM</option>
                        <option value="vgp">VGP</option>
                        <option value="kishkintha">KISHKINTA</option>
                      </select>
                    )}
                  />
                </div>
                <div className="col-3">
                  <Controller
                    control={control}
                    name="datePickerStartToEnd"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <DatePicker
                        // {...field}
                        value={value}
                        className=" col-12 salesReport_datePicker_startToEnd"
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        // onChange={onChange}
                        onChange={(update) => {
                          setDateRange(update)
                        }}
                        withPortal
                        placeholderText="Start Date - End Date"
                      />
                    )}
                  />
                </div>
              </form>
            </div>
            <div className="row p-3">
              <TableContainer sx={{ maxHeight: 600 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className="salesReport_tableHead"
                        style={{ borderLeft: '3px solid #4959aa' }}
                      >
                        DATE
                      </TableCell>
                      <TableCell className="salesReport_tableHead">
                        TICKET NAME
                      </TableCell>
                      <TableCell className="salesReport_tableHead">
                        COUNT
                      </TableCell>
                      <TableCell
                        className="salesReport_tableHead"
                        style={{ borderRight: '3px solid #4959aa' }}
                      >
                        PRICE
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody
                    className="salesReport_"
                    style={{ overflowY: 'scroll', maxHeight: '400px' }}
                  >
                    {salesReport.map((data) => (
                      <TableRow
                        className="salesReport_tableData_container"
                        style={{ overflowY: 'scroll', maxHeight: '400px' }}
                      >
                        <TableCell
                          className="salesReport_tableData"
                          style={{ borderLeft: '3px solid #4959aa' }}
                        >
                          {data.date}
                        </TableCell>
                        <TableCell className="salesReport_tableData">
                          {data.ticketName}
                        </TableCell>
                        <TableCell className="salesReport_tableData">
                          {data.count}
                        </TableCell>
                        <TableCell className="salesReport_tableData">
                          {data.price}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
