import React, { useEffect, useState } from 'react'
import Sidebars from '../components/Sidebars'
import InputField from '../components/InputField'
import { Controller, useForm } from 'react-hook-form'
import TicketSlotModal from '../components/TicketSlotModal'
import { useNavigate } from 'react-router-dom'
import PopUpModal from '../components/PopUpModal'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import { useMutation } from 'react-query'
const EditTicket = () => {
  const { control, handleSubmit, setValue } = useForm()
  const [addSubTicketPopUp, setAddSubTicketPopUp] = useState(null)
  const [base64Image, setBase64Image] = useState(null)
  const [ticketCategory, setTicketCategory] = useState('')
  const [startDate, setStartDate] = useState('')
  const { state } = useLocation()
  console.log('STATE VALUE', state)
  useEffect(() => {
    setValue('ticketName', state?.ticketname)
    setValue('ticketAmount', state?.ticketamount)
    setValue('ticketDate', state?.ticketDate)
    setValue('ticketValidity', state?.ticketvalidity)
    setValue('uploadImage', state?.uploadimage)
    setValue('category', ticketCategory)
    setValue('ticketDescription', state?.ticketdescription)
  }, [])

  const navigate = useNavigate()

  const handleImageInputChange = (event) => {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        const base64String = reader.result
        const base64LetterRemove = base64String.substring(
          base64String.indexOf(',') + 1,
        )
        setBase64Image(base64LetterRemove)
      }
      reader.onerror = (error) => {
        console.error('Error occurred while converting image to base64:', error)
      }
    }
  }

  const { mutate, isLoading, isError, isSuccess } = useMutation(
    (postdata) =>
      axios.put(
        `https://api.2kvirtualworld.com/ticketBook/addtickets/${state.id}`,
        postdata,
      ),
    {
      onSuccess: (val) => {
        console.log('data updated successfully..')
      },
      onError: (error) => {
        console.error('Mutation failed:', error)
      },
    },
  )
  const Submit = (data) => {
    let formData
    if (state && state.id !== null) {
      formData = {
        ticketname: data?.ticketName,
        ticketamount: Number(data?.ticketAmount),
        ticketDate: moment(data?.ticketDate).format('YYYY-MM-DD'),
        ticketvalidity: moment(data?.ticketvalidity).format('YYYY-MM-DD'),
        uploadImage: data?.uploadImage,
        ticketdescription: data?.ticketDescription,
        category: ticketCategory,
      }
      mutate(formData)
    }
    setAddSubTicketPopUp(1)
  }
  return (
    <>
      <section className="container-fluid ps-0 addTicket_mainContainer">
        {/* <Sidebars /> */}
        <div className="container  my-2 ">
          <div className="row">
            <header className="col-11  mx-5   px-0 bg-light addTicket_innerContainer_header">
              <div className=" p-4 addTicket_headed_text ">EDIT TICKET</div>
            </header>
            <div className="col-11 mx-5 px-4 py-4 addTicket_inputField_container ">
              <form onSubmit={handleSubmit(Submit)}>
                <div className="d-flex mb-4">
                  <label className="col-3 addTicket_ticket_label">
                    Ticket Name
                  </label>

                  <Controller
                    control={control}
                    name="ticketName"
                    render={(field) => (
                      <InputField
                        addTicketInputCss="--addTicket_inputFields"
                        {...field}
                        type="text"
                      />
                    )}
                    // rules={{ required: true }}
                  />
                </div>

                <div
                  className="offset-3 mb-4 d-flex justify-content-around"
                  style={{ width: '60%' }}>
                  <div className="col-3 d-flex">
                    <Controller
                      control={control}
                      name="category"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <input
                          onClick={() => {
                            setTicketCategory('adult')
                          }}
                          type="checkBox"
                          checked={ticketCategory === 'adult'}
                          className="--addTicket_checkBox_inputFields"
                          onChange={onChange}
                          defaultValue={false}
                        />
                      )}
                      // rules={{ required: true }}
                    />
                    <label>Adult Ticket</label>
                  </div>
                  <div className="col-3 d-flex">
                    <Controller
                      control={control}
                      name="category"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <input
                          onClick={() => {
                            setTicketCategory('kids')
                          }}
                          onChange={onChange}
                          type="checkBox"
                          checked={ticketCategory === 'kids'}
                          className="--addTicket_checkBox_inputFields"
                          defaultValue={false}
                        />
                      )}
                      // rules={{ required: true }}
                    />
                    <label>Kids Ticket</label>
                  </div>
                </div>

                <div className="col-12 mb-4 d-flex">
                  <label className=" col-3 addTicket_ticket_label">
                    Ticket Amount
                  </label>
                  <Controller
                    control={control}
                    name="ticketAmount"
                    render={(field) => (
                      <InputField
                        addTicketInputCss="--addTicket_inputFields"
                        {...field}
                        type="number"
                      />
                    )}
                  />
                </div>
                <div className="col-12 mb-4 d-flex">
                  <label className=" col-3 addTicket_ticket_label">
                    Ticket Date
                  </label>
                  <Controller
                    control={control}
                    name="ticketDate"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <DatePicker
                        className="addTicket_DateInput_inputFields"
                        value={value}
                        dateFormat={'yyyy-MM-dd'}
                        minDate={moment().toDate()}
                        maxDate={moment().toDate()}
                        startDate={moment().toDate()}
                        endDate={startDate}
                        selected={value}
                        onChange={(date) => onChange(date)}
                      />
                    )}
                  />
                </div>

                <div className="col-12 mb-4 d-flex">
                  <label className="col-3 addTicket_ticket_label">
                    Ticket Validity
                  </label>

                  <Controller
                    control={control}
                    name="ticketValidity"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      // <InputField
                      //   addTicketInputCss="--addTicket_inputFields"
                      //   {...field}
                      //   type="text"
                      // />
                      <DatePicker
                        className="addTicket_DateInput_inputFields"
                        value={value}
                        dateFormat={'yyyy-MM-dd'}
                        minDate={moment().toDate()}
                        selected={value}
                        onChange={(date) => onChange(date)}
                      />
                    )}
                    // rules={{ required: true }}
                  />
                </div>

                {/* <div className="col-12 mb-4 d-flex">
              <label className="col-3 addTicket_ticket_label">
                Upload Image
              </label>
              <div className="--addTicket_uploadImage_inputFields">
                <Controller
                  control={control}
                  name="uploadImage"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <input
                      className="addTicket_uploadImage_input"
                      accept="image/*"
                      type="file"
                      onChange={(e) => onChange(handleImageInputChange(e))}
                    />
                  )}
                />
                <img
                  style={{
                    height: "25px",
                    width: "25px",
                    borderRadius: "6px",
                  }}
                  src="/assets/addImage.png"
                  alt="addImage"
                />
              </div>
            </div> */}

                <div className="col-12  d-flex">
                  <label className="col-3 addTicket_ticket_label">
                    Ticket description
                  </label>

                  <Controller
                    control={control}
                    name="ticketDescription"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <textarea
                        value={value}
                        onChange={onChange}
                        className="--addTicket_description_inputFields"></textarea>
                    )}
                    // rules={{ required: true }}
                  />
                </div>

                <div className="addTicket_submitBtn_field --model-filed ">
                  {/* <div className="addTicket_ticket_label"></div> */}
                  <button
                    className="offset-3  addTicket_submitBtn --model"
                    type="submit">
                    {' '}
                    SUBMIT
                  </button>
                </div>
              </form>
            </div>
          </div>
          <PopUpModal isOpen={addSubTicketPopUp === null}>
            <div className="popup-overlay">
              <div className="confirmation-popup">
                <div style={{ height: '120px' }}>
                  <img
                    src="/assets/edit_back.jpg"
                    alt="backgroundImage"
                    className="p-0 m-0 popUpBackGround"
                  />
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <span className="popUpContent ">Thank you!</span>
                  <span className="popUpContentPara py-3">
                    Your ticket has been updated successfully
                  </span>
                  <button
                    onClick={() => {
                      setAddSubTicketPopUp(null)
                      navigate('/ticketView')
                    }}>
                    OK
                  </button>
                </div>
              </div>
            </div>
          </PopUpModal>
        </div>
      </section>
    </>
  )
}

export default EditTicket
