import React, { useEffect, useState } from 'react'
import Sidebars from '../components/Sidebars'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { TablePagination } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import ReactDatePicker from 'react-datepicker'
import { useQuery } from 'react-query'
import { useLocation } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import axios from 'axios'
import { getBookingTable } from '../globalApi/Api'
const BookingTable = () => {
  const { control } = useForm()
  const { state } = useLocation()
  const [dateRange, setDateRange] = useState([null, null])
  // const [startDate, endDate] = dateRange
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [page, pageChange] = useState(0)
  const [rowperpage, rowperpageChange] = useState(8)
  const navigate = useNavigate()
  let handlepageChange = (event, newpage) => {
    pageChange(newpage)
  }
  let handlerowsperpageChange = (event) => {
    rowperpageChange(+event.target.value)
    pageChange(0)
  }
  const { data, isLoading, isError, isRefetching } = useQuery(
    'data',
    getBookingTable,
  )
  let details = data && data?.data
  console.log('DETAILS', details)

  const [selectedType, setSelectedType] = useState('')
  const [filteredData, setFilteredData] = useState(details && details)
  // useEffect(() => {
  //   setFilteredData(details)
  // }, [details])
  // // Function to handle select change
  // const handleSelectChange = (event) => {
  //   const type = event.target.value
  //   setSelectedType(type)
  //   // Filter data based on selected type
  //   if (type == '') {
  //     setFilteredData(details && details) // Show all data if type is empty
  //   } else {
  //     const filtered = details?.filter((item) => item.ticketname === type)
  //     setFilteredData(filtered)
  //   }
  // }
  const filteredDatas = details?.filter((item) => {
    const itemDate = new Date(item.date)
    return (
      (!startDate || itemDate >= startDate) && (!endDate || itemDate <= endDate)
    )
  })
  
  
  return (
    <>
      <section className="main_Section d-flex justify-content-center">
        {/* <Sidebars /> */}
        <div className="container overflow-auto px-5 py-3">
          <div className="row booking_table_view">
            <div className="col-lg-12 p-4 d-flex justify-content-end align-items-center">
              {/* <div>
                <button className="download_btn px-3 py-1">Download</button>
              </div> */}
              <div>
                {/* <select
                  value={selectedOption}
                  onChange={handleSelectChange}
                
                >
                  <option value="">None</option>
                  <option value="MGM">MGM</option>
                  <option value="VGP">VGP</option>
                </select> */}
                {/* <select
                  value={selectedType}
                  onChange={handleSelectChange}
                  className="booking_theme_park_select_option"
                >
                  <option value="">All</option>
                  <option value="MGM">MGM</option>
                  <option value="VGP">VGP</option>
                </select> */}
              </div>
              <div className="d-flex align-items-center gap-2 justify-content-end" style={{width:"40%"}}>
              <label className="startDate">START</label>
               <div className="d-flex align-items-center startDatePicker px-2">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="Start date"
                  className="startDatePickers"
                />
                <img src="/assets/Subtract.svg" />
              </div>
            </div>
            <div className="d-flex align-items-center gap-2 justify-content-end" style={{width:"40%"}}>
              <label className="startDate">END</label>
              <div className="d-flex align-items-center startDatePicker px-2">
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  placeholderText="End date"
                  className="startDatePickers"
                />
                <img src="/assets/Subtract.svg" />
              </div>
            </div>
              {/* <div>
                <Controller
                  control={control}
                  name="datePickerStartToEnd"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ReactDatePicker
                      value={value}
                      className=" col-12 salesReport_datePicker_startToEnd"
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={(update) => {
                        setDateRange(update)
                      }}
                      withPortal
                      placeholderText="Start Date - End Date"
                    />
                  )}
                />
              </div> */}
            </div>
            <div className="col-lg-12 p-3 ">
              <div className="table-container">
                <TableContainer>
                  <Table>
                    <TableHead className="bookingtableheading">
                      <TableRow>
                        <TableCell className="bookingtablehead">
                          Customer Name
                        </TableCell>
                        <TableCell className="bookingtablehead">
                          Location
                        </TableCell>
                        <TableCell className="bookingtablehead">
                          Adult Count
                        </TableCell>
                        <TableCell className="bookingtablehead">
                          Child Count
                        </TableCell>
                        <TableCell className="bookingtablehead">Date</TableCell>
                        <TableCell className="bookingtablehead">Slot</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="tablebody">
                      {filteredDatas &&
                        filteredDatas
                          ?.slice(
                            page * rowperpage,
                            page * rowperpage + rowperpage,
                          )
                          .map((data, i) => {
                            return (
                              <>
                                <TableRow
                                  key={i}
                                  onClick={() => {
                                    navigate('/bookingManagementEditPage', {
                                      state: data,
                                    })
                                  }}
                                >
                                  <TableCell className="bookingtabledata">
                                    {data.customername}
                                  </TableCell>
                                  <TableCell className="bookingtabledata">
                                    {data.ticketname}
                                  </TableCell>
                                  <TableCell className="bookingtabledata">
                                    {data.adulttickets}
                                  </TableCell>
                                  <TableCell className="bookingtabledata">
                                    {data.kidstickets}
                                  </TableCell>
                                  <TableCell className="bookingtabledata">
                                    {data.date}
                                  </TableCell>
                                  <TableCell className="bookingtabledata">
                                    {data.slot}
                                  </TableCell>
                                </TableRow>
                              </>
                            )
                          })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
            <div className="col-lg-6 p-2">
              <p className="product_paragraph_footer">
                Showing {page + 1} of {Math.ceil(filteredDatas?.length / rowperpage)}{' '}
                pages
              </p>
            </div>
            <div className="col-lg-6 p-2 ">
              <TablePagination
                rowsPerPageOptions={[filteredDatas?.length]}
                rowsPerPage={rowperpage}
                page={page}
                count={filteredDatas?.length}
                component="div"
                onPageChange={handlepageChange}
                onRowsPerPageChange={handlerowsperpageChange}
              ></TablePagination>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default BookingTable
