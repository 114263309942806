import React, { useEffect, useState } from 'react'
import Sidebars from '../components/Sidebars'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useQuery } from 'react-query'
import axios from 'axios'
import { useSelector } from 'react-redux'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { getViewTicketDetails } from '../globalApi/Api'
const TicketView = () => {
  const { state } = useLocation()
  const [isRotated, setIsRotated] = useState(false)
  const [borderBottom, setBorderBottom] = useState(true)
  const [mgmTableData, setMgmTableData] = useState(false)
  // const [vgpUniversalTableData, setVgpUniversalTableData] = useState(false);
  // const [vgpKingdomTableData, setVgpKingdomTableData] = useState(false);
  // const loginDetails = useSelector((state) => state.loginDetails.loginUser);
  // console.log("loginDetails", loginDetails.companyname);
  const toggleRotation = () => {
    setIsRotated(!isRotated)
  }

  const { data, isLoading, isError, isRefetching } = useQuery(
    'data',
    getViewTicketDetails,
  )

  let details = data && data?.data
  // console.log("DETAILS", details);
  const navigate = useNavigate()

  return (
    <>
      <section className="container-fluid ps-0 addTicket_mainContainer ">
        {/* <Sidebars /> */}
        <div className="container overflow-auto mx-4">
          <div className="row vh-100 justify-content-center align-items-center p-0 m-0">
            <div className="col-lg-12 p-0 m-0 ticket_view_form_block">
              <div className="view_ticket_heading_block">
                <h3 className="view_ticket_heading"> VIEW TICKET</h3>
              </div>
              <div
                style={{
                  borderBottom: borderBottom ? '2px solid #4959aa ' : 'none',
                }}
                onClick={() => {
                  setMgmTableData(!mgmTableData)
                }}
              >
                <div
                  className=" p-3 view_ticket_block"
                  onClick={() => {
                    // setEditImageToggle(!editImageToggle)
                  }}
                >
                  <h3 className="view_ticket_sub_heading">
                    {/* {loginDetails.companyname} */}
                    MGM Theme Park
                  </h3>

                  <div className="editImageBlock" onClick={toggleRotation}>
                    <img
                      className={`imageSize ${isRotated ? 'rotate' : ''}`}
                      alt="arrowIcon"
                      src="assets/downArrow.png"
                    />
                  </div>
                </div>
                <div className="table_container">
                  {mgmTableData && (
                    <div className="table-responsive">
                      <table className="table  table-bordered">
                        <thead>
                          <tr>
                            <th className="mgm_table_data_head">TICKET ID</th>
                            <th className="mgm_table_data_head">TICKET NAME</th>
                            <th className="mgm_table_data_head">CATEGORY</th>
                            <th className="mgm_table_data_head">AMOUNT</th>
                            <th className="mgm_table_data_head">TICKET DATE</th>
                            <th className="mgm_table_data_head">VALIDITY</th>
                            <th className="mgm_table_data_head">DESCRIPTION</th>
                            <th className="mgm_table_data_head">EDIT</th>
                          </tr>
                        </thead>
                        <tbody>
                          {details &&
                            details?.map((item, index) => {
                              return (
                                <>
                                  <tr key={index}>
                                    <td className="mgm_table_data">
                                      {item?.id}
                                    </td>
                                    <td className="mgm_table_data">
                                      {item?.ticketname}
                                    </td>
                                    <td className="mgm_table_data">
                                      {item?.category}
                                    </td>
                                    <td className="mgm_table_data">
                                      {item?.ticketamount}
                                    </td>
                                    <td className="mgm_table_data">
                                      {item?.ticketDate}
                                    </td>
                                    <td className="mgm_table_data">
                                      {item?.ticketvalidity}
                                    </td>
                                    <td className="mgm_table_data">
                                      {item?.ticketdescription}
                                    </td>
                                    <td className="mgm_table_data">
                                      <img
                                        src="/assets/editIconBlack.png"
                                        className="edit_image"
                                        alt="edit-image"
                                        onClick={() => {
                                          navigate('/editPage', {
                                            state: item,
                                          })
                                        }}
                                      />
                                    </td>
                                  </tr>
                                </>
                              )
                            })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                {/* <div className="table_container">
                  {vgpUniversalTableData && (
                    <div className="table-responsive">
                      <table className="table  table-bordered">
                        <thead>
                          <tr>
                            <th className="mgm_table_data_head">TICKET ID</th>
                            <th className="mgm_table_data_head">TICKET NAME</th>
                            <th className="mgm_table_data_head">CATEGORY</th>
                            <th className="mgm_table_data_head">AMOUNT</th>
                            <th className="mgm_table_data_head">TICKET DATE</th>
                            <th className="mgm_table_data_head">VALIDITY</th>
                            <th className="mgm_table_data_head">DESCRIPTION</th>
                            <th className="mgm_table_data_head">EDIT</th>
                          </tr>
                        </thead>
                        <tbody>
                          {details?.map((data, index) => {
                            return (
                              <>
                                <tr key={index}>
                                  <td className="mgm_table_data">{data.id}</td>
                                  <td className="mgm_table_data">
                                    {data.ticketname}
                                  </td>
                                  <td className="mgm_table_data">
                                    {data.category}
                                  </td>
                                  <td className="mgm_table_data">
                                    {data.ticketamount}
                                  </td>
                                  <td className="mgm_table_data">
                                    {data.ticketDate}
                                  </td>
                                  <td className="mgm_table_data">
                                    {data.ticketvalidity}
                                  </td>
                                  <td className="mgm_table_data">
                                    {data.ticketdescription}
                                  </td>
                                  <td className="mgm_table_data"
                                  onClick={() => {
                                        navigate("/editPage", { state: data });
                                      }}
                                  >
                                    <img
                                      src="/assets/editIconBlack.png"
                                      className="edit_image"
                                      alt="edit-image"
                                      
                                    />
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <div className="table_container">
                  {vgpKingdomTableData && (
                    <div className="table-responsive">
                      <table className="table  table-bordered">
                        <thead>
                          <tr>
                            <th className="mgm_table_data_head">TICKET ID</th>
                            <th className="mgm_table_data_head">TICKET NAME</th>
                            <th className="mgm_table_data_head">CATEGORY</th>
                            <th className="mgm_table_data_head">AMOUNT</th>
                            <th className="mgm_table_data_head">TICKET DATE</th>
                            <th className="mgm_table_data_head">VALIDITY</th>
                            <th className="mgm_table_data_head">DESCRIPTION</th>
                            <th className="mgm_table_data_head">EDIT</th>
                          </tr>
                        </thead>
                        <tbody>
                          {details?.map((data, index) => {
                            return (
                              <>
                                <tr key={index}>
                                  <td className="mgm_table_data">{data.id}</td>
                                  <td className="mgm_table_data">
                                    {data.ticketname}
                                  </td>
                                  <td className="mgm_table_data">
                                    {data.category}
                                  </td>
                                  <td className="mgm_table_data">
                                    {data.ticketamount}
                                  </td>
                                  <td className="mgm_table_data">
                                    {data.ticketDate}
                                  </td>
                                  <td className="mgm_table_data">
                                    {data.ticketvalidity}
                                  </td>
                                  <td className="mgm_table_data">
                                    {data.ticketdescription}
                                  </td>
                                  <td className="mgm_table_data">
                                    <img
                                      src="/assets/editIconBlack.png"
                                      className="edit_image"
                                      alt="edit-image"
                                      onClick={() => {
                                        navigate("/editPage", { state: data });
                                      }}
                                    />
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default TicketView
