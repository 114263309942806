import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import Sidebars from '../components/Sidebars'
import { useMutation } from 'react-query'
import { addNotificationPostMethod } from '../globalApi/Api'
import PopUpModal from '../components/PopUpModal'

export default function Notification() {
  const { control, handleSubmit, setValue, reset, watch } = useForm()
  const [addTicketPopUp, setAddTicketPopUp] = useState(null)

  const { mutate, isLoading, isError, isSuccess } = useMutation(
    (postData) => addNotificationPostMethod(postData),
    {
      onSuccess: (data) => {
        console.log('Data_formate', data?.data)
        reset()
        setAddTicketPopUp(1)
      },
      onError: (error) => {
        console.error('Mutation failed:', error)
      },
    },
  )
  const Submit = (data) => {
    console.log('data', data)
    const value = {
      description: data.description,
    }
    mutate(value)
    console.log('value', value)
  }
  return (
    <section className="container-fluid ps-0 addTicket_mainContainer">
      {/* <Sidebars /> */}

      <div className="container my-2">
        <div className="row">
          <header className="col-lg-11 mx-5   px-0 bg-light addTicket_innerContainer_header">
            <div className=" p-4 addTicket_headed_text ">Notification</div>
          </header>
          <div
            style={{ height: '83vh' }}
            className="col-lg-11 mx-5 px-4 py-3 addTicket_inputField_container "
          >
            <form onSubmit={handleSubmit(Submit)}>
              <div>
                <div className="d-flex flex-grow-1">
                  <label className=" col-2 pt-2 addTicket_ticket_label">
                    Description
                  </label>
                  <div className="col-8">
                    <Controller
                      control={control}
                      name="description"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <textarea
                          value={value}
                          onChange={onChange}
                          className=" notification_description_inputFields"
                          maxLength={300}
                          placeholder="Enter the description here"
                        ></textarea>
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                </div>
                <div className="offset-2 d-flex notification_submitBtn_field  ">
                  <button
                    className=" col-6 notification_submitBtn "
                    type="submit"
                  >
                    {' '}
                    SUBMIT
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <PopUpModal isOpen={addTicketPopUp === null}>
        <div className="popup-overlay">
          <div className="confirmation-popup">
            <div style={{ height: '120px' }}>
              <img
                src="/assets/edit_back.jpg"
                alt="backgroundImage"
                className="p-0 m-0 popUpBackGround"
              />
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <span className="popUpContent ">Thank you!</span>
              <span className="popUpContentPara py-3">
                Your ticket has been added successfully
              </span>
              <button
                onClick={() => {
                  setAddTicketPopUp(null)
                }}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </PopUpModal>
    </section>
  )
}
