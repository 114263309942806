import React from 'react'
import Sidebars from '../components/Sidebars'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
// import { viewComboGetMethod } from "../globalApi/Api";
import axios from 'axios'
import {
  getPurchasesReport,
  viewComboDeleteMethod,
  viewComboGetMethod,
} from '../globalApi/Api'

export default function ViewCombo() {
  const navigate = useNavigate()
  const { state } = useLocation()

  const comboIndexDelete = async (itemId) => {
    // const confirmDelete = window.confirm("Are you sure you want to delete?");
    console.log('itemId', itemId)
    if (itemId) {
      try {
        viewComboDeleteMethod(itemId)
        isRefetching()
        window.location.reload()
      } catch (error) {
        console.log('Error', error)
      }
      console.log('Deleted!')
    }
  }

  const { data, isLoading, isError, isRefetching } = useQuery(
    'viewCombo',
    viewComboGetMethod,
  )

  const viewComboList = data && data?.data

  console.log('data', data && data?.data)
  return (
    <>
      <section className="container-fluid ps-0 addTicket_mainContainer">
        {/* <Sidebars /> */}

        <div className="container my-2">
          <div className="row">
            <header className="col-lg-11 mx-5   px-0 bg-light addTicket_innerContainer_header">
              <div className=" p-4 addTicket_headed_text ">VIEW COMBO</div>
            </header>
            <div
              style={{ height: '83vh' }}
              className="col-lg-11 mx-5 px-4 py-3 addTicket_inputField_container"
            >
              <div className="row p-3">
                <TableContainer sx={{ maxHeight: 600 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className="viewCombo_tableHead"
                          // style={{ borderLeft: "3px solid black" }}
                        >
                          COMBO ID
                        </TableCell>
                        <TableCell className="viewCombo_tableHead">
                          COMBO NAME
                        </TableCell>
                        <TableCell className="viewCombo_tableHead">
                          AMOUNT
                        </TableCell>
                        {/* <TableCell className="viewCombo_tableHead">
                          VALIDITY
                        </TableCell> */}
                        <TableCell className="viewCombo_tableHead">
                          PLACES
                        </TableCell>
                        <TableCell
                          className="viewCombo_tableHead"
                          style={{ borderRight: 'none' }}
                        >
                          Edit/Delete
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody
                      className="viewCombo_tableData_container"
                      style={{ overflowY: 'scroll', maxHeight: '400px' }}
                    >
                      {viewComboList &&
                        viewComboList?.map((item, index) => (
                          <TableRow
                            className=""
                            style={{ overflowY: 'scroll', maxHeight: '400px' }}
                          >
                            <TableCell
                              className="viewCombo_tableData"
                              //   style={{ borderLeft: "3px solid #000" }}
                            >
                              {item.id}
                            </TableCell>
                            <TableCell className="viewCombo_tableData">
                              {item.comboname}
                            </TableCell>
                            <TableCell className="viewCombo_tableData">
                              {item.totalprice}
                            </TableCell>
                            {/* <TableCell className="viewCombo_tableData">
                            {data.validity}
                          </TableCell> */}
                            <TableCell className="viewCombo_tableData">
                              {item.selectcombo}
                            </TableCell>
                            <TableCell
                              className="viewCombo_editIcon_tableData"
                              style={{ borderRight: 'none' }}
                            >
                              <img
                                className="pe-2"
                                onClick={() => {
                                  navigate('/editCombo', { state: item })
                                  console.log('hello', item)
                                }}
                                src="/assets/Vector.jpg"
                                alt="editIcon"
                              />
                              <img
                                onClick={() => {
                                  comboIndexDelete(item)
                                }}
                                src="/assets/delete.svg"
                                alt="editIcon"
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
