import React, { useState } from 'react'
import Sidebars from '../components/Sidebars'
import { useNavigate } from 'react-router-dom'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { useQuery } from 'react-query'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { getViewSubTicketDetails } from '../globalApi/Api'
const ViewSubTicket = () => {
  const { state } = useLocation()
  const [isRotated, setIsRotated] = useState(false)
  const [borderBottom, setBorderBottom] = useState(true)
  const [mgmTableData, setMgmTableData] = useState(false)
  const [vgpUniversalTableData, setVgpUniversalTableData] = useState(false)
  const [vgpKingdomTableData, setVgpKingdomTableData] = useState(false)

  const toggleRotation = () => {
    setIsRotated(!isRotated)
  }

  const { data, isLoading, isError, isRefetching } = useQuery(
    'data',
    getViewSubTicketDetails,
  )

  let details = data && data?.data
  console.log('subtickets', details)
  const navigate = useNavigate()
  return (
    <>
      <section className="container-fluid ps-0 addTicket_mainContainer">
        {/* <Sidebars /> */}
        <div className="container overflow-auto mx-4">
          <div className="row vh-100 justify-content-center align-items-center p-0 m-0">
            <div className="col-lg-12 p-0 m-0 ticket_view_form_block">
              <div className="view_ticket_heading_block">
                <h3 className="view_ticket_heading"> VIEW SUB TICKET</h3>
              </div>
              <div
                onClick={() => {
                  setMgmTableData(!mgmTableData)
                  setVgpUniversalTableData(false)
                  setVgpKingdomTableData(false)
                }}
                style={{
                  borderBottom: borderBottom ? '2px solid #4959aa ' : 'none',
                }}
              >
                <div
                  className=" p-3 view_ticket_block"
                  onClick={() => {
                    // setEditImageToggle(!editImageToggle)
                  }}
                >
                  <h3 className="view_ticket_sub_heading">MGM Theme Park</h3>

                  <div className="editImageBlock" onClick={toggleRotation}>
                    <img
                      className={`imageSize ${isRotated ? 'rotate' : ''}`}
                      alt="arrowIcon"
                      src="assets/downArrow.png"
                    />
                  </div>
                </div>
                <div className="table_container">
                  {mgmTableData && (
                    <div className="table-responsive ">
                      <TableContainer sx={{ maxHeight: 350 }}>
                        <Table
                          stickyHeader
                          aria-label="sticky table"
                          className="table  table-bordered"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell className="mgm_table_data_head">
                                TICKET ID
                              </TableCell>
                              <TableCell className="mgm_table_data_head">
                                TICKET NAME
                              </TableCell>
                              <TableCell className="mgm_table_data_head">
                                CATEGORY
                              </TableCell>
                              <TableCell className="mgm_table_data_head">
                                AMOUNT
                              </TableCell>
                              <TableCell className="mgm_table_data_head">
                                Ticket Date
                              </TableCell>
                              <TableCell className="mgm_table_data_head">
                                VALIDITY
                              </TableCell>
                              <TableCell className="mgm_table_data_head">
                                DESCRIPTION
                              </TableCell>
                              <TableCell className="mgm_table_data_head">
                                EDIT
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {details?.map((data, i) => {
                              return (
                                <TableRow key={i}>
                                  <TableCell className="mgm_table_data">
                                    {data.id}
                                  </TableCell>
                                  <TableCell className="mgm_table_data">
                                    {data.ticketname}
                                  </TableCell>
                                  <TableCell className="mgm_table_data">
                                    {data.category}
                                  </TableCell>
                                  <TableCell className="mgm_table_data">
                                    {data.ticketamount}
                                  </TableCell>
                                  <TableCell className="mgm_table_data">
                                    {data.ticketDate}
                                  </TableCell>
                                  <TableCell className="mgm_table_data">
                                    {data.ticketvalidity}
                                  </TableCell>

                                  <TableCell className="mgm_table_data">
                                    {data.ticketdescription}
                                  </TableCell>
                                  <TableCell
                                    className="mgm_table_data"
                                    onClick={() => {
                                      navigate('/editSubTicketPage', {
                                        state: data,
                                      })
                                    }}
                                  >
                                    <img
                                      className="edit_image"
                                      alt="edit_icon"
                                      src="/assets/editIconBlack.png"
                                    />
                                  </TableCell>
                                </TableRow>
                              )
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  )}
                </div>
              </div>
              {/* <div
                onClick={() => {
                  setVgpUniversalTableData(!vgpUniversalTableData);
                  setMgmTableData(false);
                  setVgpKingdomTableData(false);
                  // setBorderBottom(false);
                }}
                style={{
                  borderBottom: borderBottom ? "2px solid #4959aa " : "none",
                }}
              >
                <div className="p-3 view_ticket_block">
                  <h3 className="view_ticket_sub_heading">
                    VGP Universal Kingdom
                  </h3>

                  <div className="editImageBlock"  onClick={toggleRotation}>
                  <img
                      className={`imageSize ${isRotated ? "rotate" : ""}`}
                      alt="arrowIcon"
                      src="assets/downArrow.png"
                    />
                  </div>
                </div>
                <div className="table_container">
                  {vgpUniversalTableData && (
                    <div className="table-responsive ">
                    <TableContainer  sx={{ maxHeight: 350 }} >
                        <Table
                          stickyHeader
                          aria-label="sticky table"
                          className="table  table-bordered"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell className="mgm_table_data_head">
                                TICKET ID
                              </TableCell>
                              <TableCell className="mgm_table_data_head">
                                TICKET NAME
                              </TableCell>
                              <TableCell className="mgm_table_data_head">
                                AMOUNT
                              </TableCell>
                              <TableCell className="mgm_table_data_head">
                                VALIDITY
                              </TableCell>
                              <TableCell className="mgm_table_data_head">
                                DESCRIPTION
                              </TableCell>
                              <TableCell className="mgm_table_data_head">
                                EDIT
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {tableData?.map((data, i) => {
                              return (
                                <TableRow key={i}>
                                  <TableCell className="mgm_table_data">
                                    {data.ticketId}
                                  </TableCell>
                                  <TableCell className="mgm_table_data">
                                    {data.ticketName}
                                  </TableCell>
                                  <TableCell className="mgm_table_data">
                                    {data.amount}
                                  </TableCell>
                                  <TableCell className="mgm_table_data">
                                    {data.validity}
                                  </TableCell>
                                  <TableCell className="mgm_table_data">
                                    {data.description}
                                  </TableCell>
                                  <TableCell className="mgm_table_data">
                                    <img
                                      className="edit_image"
                                      alt="edit_icon"
                                      src={data.edit}
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  )}
                </div>
              </div>
              <div
                onClick={() => {
                  setVgpKingdomTableData(!vgpKingdomTableData);
                  setMgmTableData(false);
                  setVgpUniversalTableData(false);
                }}
                style={{
                  borderBottom: borderBottom ? "2px solid #4959aa " : "none",
                }}
              >
                <div className="p-3 view_ticket_block">
                  <h3 className="view_ticket_sub_heading">
                    VGP Marine Kingdom
                  </h3>

                  <div className="editImageBlock" onClick={toggleRotation}>
                    <img
                      className={`imageSize ${isRotated ? "rotate" : ""}`}
                      alt="arrowIcon"
                      src="assets/downArrow.png"
                    />
                  </div>
                </div>
                <div className="table_container">
                  {vgpKingdomTableData && (
                    <div className="table-responsive">
                    <TableContainer  sx={{ maxHeight: 350 }} >
                        <Table
                          stickyHeader
                          aria-label="sticky table"
                          className="table  table-bordered"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell className="mgm_table_data_head">
                                TICKET ID
                              </TableCell>
                              <TableCell className="mgm_table_data_head">
                                TICKET NAME
                              </TableCell>
                              <TableCell className="mgm_table_data_head">
                                AMOUNT
                              </TableCell>
                              <TableCell className="mgm_table_data_head">
                                VALIDITY
                              </TableCell>
                              <TableCell className="mgm_table_data_head">
                                DESCRIPTION
                              </TableCell>
                              <TableCell className="mgm_table_data_head">
                                EDIT
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {tableData?.map((data, i) => {
                              return (
                                <TableRow key={i}>
                                  <TableCell className="mgm_table_data">
                                    {data.ticketId}
                                  </TableCell>
                                  <TableCell className="mgm_table_data">
                                    {data.ticketName}
                                  </TableCell>
                                  <TableCell className="mgm_table_data">
                                    {data.amount}
                                  </TableCell>
                                  <TableCell className="mgm_table_data">
                                    {data.validity}
                                  </TableCell>
                                  <TableCell className="mgm_table_data">
                                    {data.description}
                                  </TableCell>
                                  <TableCell className="mgm_table_data">
                                    <img
                                      className="edit_image"
                                      alt="edit_icon"
                                      src={data.edit}
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  )}
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ViewSubTicket
