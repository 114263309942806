import React, { useState } from 'react'
import { Bar } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Controller, useForm } from 'react-hook-form'
import DatePicker from 'react-datepicker'
import { useQuery } from 'react-query'
import {
  getAdultTicketCounts,
  getChildTicketCounts,
  getAdultTicketDateCount,
  getChildTicketDateCount,
} from '../globalApi/Api'
import axios from 'axios'
import Barchart from '../components/Barchart'
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const Dashboard = () => {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const getAdultTicket = useQuery('data', getAdultTicketCounts)
  const getChildTicket = useQuery('data', getChildTicketCounts)
  const getAdultTicketDate = useQuery('data', getAdultTicketDateCount)
  const getChildTicketDate = useQuery('data', getAdultTicketDateCount)
 
  const { control } = useForm()

  let sumofAdultTicket = getAdultTicket && getAdultTicket?.data?.data
  let sumofChiltTicket = getChildTicket && getChildTicket?.data?.data
  let adultTicketDate = getAdultTicketDate && getAdultTicketDate?.data?.data
  let childTicketDate = getChildTicketDate && getChildTicketDate?.data?.data

  return (
    <>
      <section className="main_Section">
        <div className="container">
          <div className="row vh-100 justify-content-center align-items-center">
            <div className="col-lg-12 dashboardMainContainer">
              <div className="d-flex justify-content-end align-items-center py-3">
                <div>
                  <Controller
                    name="selectOption"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <select {...field} className="selectDropDownDashboard">
                        <option value="" disabled>
                          Select...
                        </option>
                        <option value="mgm">mgm</option>
                        <option value="vgp">vgp</option>
                        <option value="vgp kingdom">vgp kingdom</option>
                      </select>
                    )}
                  />
                </div>
                <div
                  className="d-flex align-items-center gap-2 justify-content-end"
                  style={{ width: '26%' }}>
                  <label className="startDate">START</label>
                  <div className="d-flex align-items-center startDatePicker px-2">
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      placeholderText="Start date"
                      className="startDatePickers"
                    />
                    <img src="/assets/Subtract.svg" />
                  </div>
                </div>
                <div
                  className="d-flex align-items-center gap-2 justify-content-end"
                  style={{ width: '26%' }}>
                  <label className="startDate">END</label>
                  <div className="d-flex align-items-center startDatePicker px-2">
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      placeholderText="End date"
                      className="startDatePickers"
                    />
                    <img src="/assets/Subtract.svg" />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center flex-column py-3">
                <div className="d-flex justify-content-center align-items-end bar-chart">
                 <Barchart />
                </div>
                <div>
                  <h6>DATE</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Dashboard
