import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import InputField from './InputField'
import TicketSlotModal from './TicketSlotModal'
import axios from 'axios'
import { useMutation } from 'react-query'
import { useSelector } from 'react-redux'


const updateUserPassword = async ({ email, newPassword, confirmPassword }) => {
  const response = await axios.put(
    `https://api.2kvirtualworld.com/WebUserManagement-0.0.1-SNAPSHOT/addusers/resetpassword?emailId=${email}&password=${newPassword}&confirmPassword=${confirmPassword}`,
    {
      email,
      newPassword,
      confirmPassword,
    },
  )
  return response.data 
}

const Reset = () => {
  const { control, handleSubmit } = useForm()
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false) // State to toggle password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false) // State to toggle confirm password visibility

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }
  const users = useSelector((state) => state?.loginDetails?.users)
  console.log('users', users)

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  const [resetPopup, setResetPopup] = useState(null)

  const mutation = useMutation(updateUserPassword)
  // let email = localStorage.getItem('emailId')
  let email = sessionStorage.getItem('resetEmail')
  console.log('emailID', email)

  const onsubmit = async (data) => {
    const { newPassword, confirmPassword } = data
    if (data?.newPassword === data?.confirmPassword) {
      try {
        await mutation.mutateAsync({ email, newPassword, confirmPassword })

        console.log('Password updated successfully!')
        setResetPopup(1)
      } catch (error) {
        console.error('Error updating password:', error)
      }
    } else {
      alert('password donot match')
    }
  }

  return (
    <>
      <section className="main_Section">
        <div className="container">
          <div className="row  vh-100">
            <div className="col-lg-12 mx-5 d-flex  flex-column justify-content-center">
              <div className="reset_form_block">
                <div className="treat_logo my-1">
                  <img
                    src="/assets/2k_logo.png"
                    style={{ width: '100%', height: '100%' }}
                  />
                </div>
                <form
                  className="d-flex flex-column form_block"
                  onSubmit={handleSubmit(onsubmit)}>
                  <div className="px-1 m-0 reset_input_block">
                    <Controller
                      control={control}
                      name="newPassword"
                      render={(field) => (
                        <InputField
                          {...field}
                          loginInputCss="reset_input_field"
                          placeholder="New Password"
                          type={showPassword ? 'text' : 'password'}
                        />
                      )}
                      rules={{ required: true }}
                    />
                     <span onClick={togglePasswordVisibility}>
                      {showPassword ? (
                        <img
                          src="/assets/visibilityOn.png"
                          style={{ width: '25px', height: '25px',cursor:"pointer" }}
                        />
                      ) : (
                        <img
                          src="/assets/visibilityOff.png"
                          style={{ width: '25px', height: '25px',cursor:"pointer" }}
                        />
                      )}
                    </span>
                  </div>
                  <div className="px-1 m-0 reset_input_block">
                    <Controller
                      control={control}
                      name="confirmPassword"
                      render={(field) => (
                        <InputField
                          {...field}
                          loginInputCss="reset_input_field"
                          placeholder="Confirm Password"
                          type={showConfirmPassword ? 'text' : 'password'}
                        />
                      )}
                      rules={{ required: true }}
                    />
                    <span onClick={toggleConfirmPasswordVisibility}>
                      {showConfirmPassword ? (
                        <img
                          src="/assets/visibilityOn.png"
                          style={{ width: '25px', height: '25px' ,cursor:"pointer"}}
                        />
                      ) : (
                        <img
                          src="/assets/visibilityOff.png"
                          style={{ width: '25px', height: '25px',cursor:"pointer" }}
                        />
                      )}
                    </span>
                  </div>
                  <div className="text-center">
                    <button className="reset_button my-3">Reset</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <TicketSlotModal isOpen={resetPopup === null}>
        <div className="popup-overlay">
          <div className="confirmation-popup" style={{ width: '37%' }}>
            <div style={{ height: '115px' }}>
              <img
                src="/assets/edit_back.jpg"
                alt="no-image"
                className="p-0 m-0 popUpBackGround"></img>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center ">
              <span className="popUpContent ">Thank you!</span>
              <span className="popUpContentPara py-3">
                {' '}
                Password has been updated successfully
              </span>

              <button
                onClick={() => {
                  navigate('/')
                }}>
                OK
              </button>
            </div>
          </div>
        </div>
      </TicketSlotModal>
    </>
  )
}

export default Reset
