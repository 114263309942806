import { useSelector } from 'react-redux'
import EditSubTicketPage from './pages/EditSubTicketPage'
import EditSubTicket from './pages/EditSubTicket'
import Login from './pages/Login'
import AddTicket from './pages/AddTicket'
import AddUser from './pages/AddUser'
import GetOtp from './components/GetOtp'
import OtpEnter from './components/OtpEnter'
import Reset from './components/Reset'
import SalesReport from './pages/SalesReport'
import AddSubTicket from './pages/AddSubTicket'
import EditTicket from './pages/EditTicket'
import TicketView from './pages/TicketView'
import EditPage from './pages/EditPage'
import Popup from './pages/Popup'
import NewBooking from './pages/NewBooking'
import BMEditPage from './pages/BMEditPage'
import BMEditTicket from './pages/BMEditTicket'
import BookingTable from './pages/BookingTable'
import Sidebars from './components/Sidebars'
import ViewSubTicket from './pages/ViewSubTicket'
import Notification from './pages/Notification'
import BookingVerification from './pages/BookingVerification'
import ViewCombo from './pages/ViewCombo'
import EditCombo from './pages/EditCombo'
import AddCombo from './pages/AddCombo'
import Trending from './pages/Trending'
import { Route, Routes } from 'react-router-dom'
import Dashboard from './pages/Dashboard'
import { useEffect, useState } from 'react'
import { login } from './redux/slices/logindetailsslice'

const AppRoutes = () => {
  const user = useSelector((state) => state?.loginDetails?.loginUser)
  let login = sessionStorage.getItem('login')
  // const [data, setData] = useState();
  // let login
  // useEffect(()=>{
  //   setData(sessionStorage.getItem('login'))
  // },[data ])
  return (
    <>
      {login ? (
        <div className="routerContainer">
          <Sidebars />
          <Routes>
            <Route path='/dashboard' element={<Dashboard/>} />
            <Route path="/ticketView" element={<TicketView />} />
            <Route path="/addTicket" element={<AddTicket />} />
            <Route path="/editTicket" element={<EditTicket />} />
            <Route path="/addUser" element={<AddUser />} />
            <Route path="/salesReport" element={<SalesReport />} />
            <Route path="/addSubTicket" element={<AddSubTicket />} />
            <Route path="/editSubTicketPage" element={<EditSubTicketPage />} />
            <Route path="/editSubTicket" element={<EditSubTicket />} />
            <Route path="/viewSubTicket" element={<ViewSubTicket />} />
            <Route path="/editPage" element={<EditPage />} />
            <Route path="/popup" element={<Popup />} />
            <Route path="/newBooking" element={<NewBooking />} />
            <Route path="/bookingManagementEditPage" element={<BMEditPage />} />
            <Route
              path="/bookingManagementEditTicket"
              element={<BMEditTicket />}
            />
            <Route path="/bookingTable" element={<BookingTable />} />
            <Route path="/notification" element={<Notification />} />
            <Route path="/viewCombo" element={<ViewCombo />} />
            <Route path="/editCombo" element={<EditCombo />} />
            <Route
              path="/bookingVerification"
              element={<BookingVerification />}
            />
            <Route path="/addCombo" element={<AddCombo />} />
            <Route path="/trending" element={<Trending />} />
          </Routes>
        </div>
      ) : (
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route path="/getOtp" element={<GetOtp />} />
          <Route path="/otpEnter" element={<OtpEnter />} />
          <Route path="/reset" element={<Reset />} />
        </Routes>
      )}
    </>
  )
}

export default AppRoutes
