import React, { useEffect, useState } from "react";
import Sidebars from "../components/Sidebars";
import { Controller, useForm } from "react-hook-form";
import InputField from "../components/InputField";
import BookingSlot from "../components/TicketSlotModal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TicketSlotModal from "../components/TicketSlotModal";
import NewTicketModal from "../components/NewTicketModal";
import { useMutation } from "react-query";
import axios from "axios";
import { newBookingPostMethod } from "../globalApi/Api";

export default function NewBooking() {
  const { control, handleSubmit, setValue, reset, watch } = useForm()

  const [ticketCount, setTicketCount] = useState(0)
  const [invoiceDetails, setInvoiceDetails] = useState()
  const [childCount, setChildCount] = useState(0)
  const [adultchildCount, setAdultChildCount] = useState(0)
  const [startDate, setStartDate] = useState()
  const [openSlotModal, setOpenSlotModal] = useState(null)
  const [openTicketBill, setOpenTicketBill] = useState(null)
  const [otpemail, setOtpmail] = useState()
  const themePark = [
    {
      image: '/public/assets/themePark.jpeg',
      adultTicket: 'KIDS TICKET',
      price: '1000',
    },
    {
      image: '/public/assets/themePark.jpeg',
      adultTicket: 'ADULT TICKET',
      price: '1000',
    },
  ]

  const { mutate, isError, isLoading, status } = useMutation(
    (postData) => newBookingPostMethod(postData), 
    {
      onSuccess: (val) => {
        console.log('OTP VERIFICATION :', val && val?.data)
        sessionStorage.setItem('otpverification', val && val?.data)
      },
      onError: (error) => {
        console.error('Mutation failed:', error)
      },
    },
  )
  const Submit = (data) => {
    setOpenTicketBill(1);
    let value = {
      customername: data?.customerName,
      customermobilenumber: data?.customerMobileNo,
      customermailid: data?.customerMailId,
      date: data?.startDate,
      slot: data?.slot,
      kidstickets: childCount,
      adulttickets: ticketCount,
      ticketname: data?.themePark,
      nooftickets: Number(childCount + ticketCount),
    }

    mutate(value)
    setInvoiceDetails(value)
    setOtpmail(data?.customerMailId)
  }
  sessionStorage.setItem('otpemail', otpemail)
  // console.log("INVOICEDETAILS",invoiceDetails)
  return (
    <section className="container-fluid ps-0 addTicket_mainContainer">
      {/* <Sidebars /> */}
      <div className="container">
        <div className="row">
          <div className="col-11 px-0 py-3 newBooking_filedInnerContainer">
            <div className="col-12 bg-light  px-0 newBooking_background_image ">
              <div className="col-12">
                <form
                  className="col-12 newBooking_inputForm_Field"
                  onSubmit={handleSubmit(Submit)}
                >
                  <div className="col-2 me-2">
                    <Controller
                      name="themePark" // Unique name for the field
                      control={control} // Control prop from useForm()
                      defaultValue="" // Default value
                      render={({ field }) => (
                        <select
                          {...field}
                          className="salesReport_themePark_select"
                        >
                          <option value="">Select a themePark</option>
                          <option value="MGM">MGM</option>
                          <option value="VGP">VGP</option>
                          <option value="KISHKINDA">KISHKINDA</option>
                        </select>
                      )}
                      
                    />
                  </div>
                  {/* <div className="col-3">
                    <Controller
                      control={control}
                      name="datePickerStartToEnd"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <InputField
                          type="search"
                          newBookingInputCss="--newBooking_search_inputFields"
                          placeholder="search"
                        />
                      )}
                    />
                  </div> */}
                </form>
              </div>
            </div>
            {/* <div className=" col-10 bg-light px-0 newBooking_TicketField"> */}
            <div className="col-12 py-2 newBooking_TicketField">
              {themePark.map((data, i) => {
                return (
                  <div className="row">
                    <div className="col-12 px-3">
                      <div>
                        <img
                          className="newBooking_themePark_image"
                          // style={{
                          //   height: "270px",

                          //   borderRadius: "8px 8px 0px 0px",
                          // }}
                          src="/assets/themePark.jpeg"
                          alt="ThemeParkImg"
                        />
                      </div>
                      {/* <div style={{display:'flex'}}> */}
                      <div
                        style={{
                          backgroundColor: '#4959aa',
                          paddingLeft: '10px',
                          borderRadius: '0px 0px 8px 8px',
                          display: 'flex',
                          justifyContent: 'space-between',
                          // border:"1px solid red"
                        }}
                      >
                        <div>
                          <div className="newBooking_ticketType">
                            {data.adultTicket}
                          </div>
                          <div>
                            <div className="newBooking_ticketType">
                              <img
                                style={{ height: '18px', paddingBottom: '2px' }}
                                src="/assets/Currency Rupee.png"
                                alt="RupeeIcon"
                              />
                              {data.price}
                            </div>
                          </div>
                        </div>
                        <div
                          className="bg-white px-2 newBooking_"
                          style={{
                            height: '25px',
                            width: '26%',
                            margin: '10px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            // border:"1px solid red"
                            //   padding:'5px'
                            //   backgroundColor: "white",
                          }}
                        >
                          <h5
                            onClick={() => {
                              if (data?.adultTicket === 'KIDS TICKET') {
                                setChildCount(childCount - 1)
                              } else {
                                setTicketCount(ticketCount - 1)
                              }
                            }}
                            className="newBooking_addSub_btn"
                          >
                            -
                          </h5>
                          <div className="newBooking_ticketCount">
                            {data?.adultTicket === 'KIDS TICKET'
                              ? childCount
                              : ticketCount}
                          </div>
                          <h5
                            onClick={() => {
                              if (data?.adultTicket === 'KIDS TICKET') {
                                setChildCount(childCount + 1)
                              } else {
                                setTicketCount(ticketCount + 1)
                              }
                            }}
                            className="newBooking_addSub_btn"
                          >
                            +
                          </h5>
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                )
              })}
            </div>
            <div className="col-12 bg-white py-2 newBooking_btnField">
              <button
                className="col-4 newBooking_btn"
                onClick={() => {
                  setOpenSlotModal(1)
                }}
              >
                BOOK NOW
              </button>
            </div>
          </div>
        </div>
      </div>
      <TicketSlotModal isOpen={openSlotModal === null}>
        <div
          className="bg-white px-0 row newBooking_TicketSlotModal_container"
          style={{ borderRadius: '15px' }}
        >
          <div className="col-12 px-0">
            <div className="px-0 col-12 newBooking_slotModel"></div>
            <form onSubmit={handleSubmit(Submit)}>
              <div className="px-5 py-3">
                <div className="pb-2">
                  <label className="newBooking_TicketSlot_Label">
                    Customer Name
                  </label>
                  <div className="">
                    <Controller
                      control={control}
                      name="customerName"
                      render={(field) => (
                        <InputField
                          newBookingTicketSlotInputCss="--newBooking_ticketSlot_inputFields"
                          {...field}
                          type="text"
                        />
                      )}
                      rules={{ required: true }}
                    />
                  </div>
                </div>
                <div className="pb-2">
                  <label className="newBooking_TicketSlot_Label">
                    Customer Mobile No
                  </label>
                  <div className="">
                    <Controller
                      control={control}
                      name="customerMobileNo"
                      render={(field) => (
                        <InputField
                          newBookingTicketSlotInputCss="--newBooking_ticketSlot_inputFields"
                          {...field}
                          type="number"
                        />
                      )}
                      rules={{ required: true }}
                    />
                  </div>
                </div>
                <div className="pb-2">
                  <label className="newBooking_TicketSlot_Label">
                    Customer Mail ID
                  </label>
                  <div className="">
                    <Controller
                      control={control}
                      name="customerMailId"
                      render={(field) => (
                        <InputField
                          newBookingTicketSlotInputCss="--newBooking_ticketSlot_inputFields"
                          {...field}
                          type="email"
                        />
                      )}
                      rules={{ required: true }}
                    />
                  </div>
                </div>
                <div className="pb-2">
                  <label className="newBooking_TicketSlot_Label">Date</label>
                  <div className="date_picker_newbooking">
                    <Controller
                      name="startDate"
                      control={control}
                      defaultValue={null}
                      render={({ field }) => (
                        <DatePicker
                          className="--newBooking_ticketSlotDate_inputFields"
                          selected={field.value}
                          onChange={(date) => field.onChange(date)}
                          selectsStart
                          startDate={field.value}
                          dateFormat="dd/MM/yyyy"
                        />
                      )}
                       rules={{ required: true }}
                    />
                  </div>
                </div>

                <div className="pb-2">
                  <label className="newBooking_TicketSlot_Label">Slot</label>
                  <div>
                    <Controller
                      control={control}
                      name="slot"
                      render={(field) => (
                        <InputField
                          newBookingTicketSlotInputCss="--newBooking_ticketSlot_inputFields"
                          {...field}
                          type="time"
                        />
                      )}
                       rules={{ required: true }}
                    />
                  </div>
                </div>
                <div className=" col-10 pt-4 newBooking_SlotSubmit_btnField">
                  <button
                    className="newBooking_slotSubmit_btn"
                    type="submit"
                    // onClick={() => {
                     
                    //   // setOpenSlotModal(null)
                    // }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </TicketSlotModal>
      <TicketSlotModal isOpen={openTicketBill === null}>
        <div className="row d-flex justify-content-center">
          <div
            className="col-11 px-0 bg-white justify-content-center"
            style={{ borderRadius: '15px' }}
          >
            <div className=" col-12 d-flex flex-column">
              <div className=" col-12  d-flex justify-content-end">
                <img
                  className="pe-2 mt-2 "
                  style={{ height: '20px', cursor: 'pointer' }}
                  src="/assets/closeIcon.png"
                  alt="closeIcon"
                  onClick={() => {
                    setOpenTicketBill(null)
                    setOpenSlotModal(null)
                  }}
                />
              </div>
              <div className="col-12 d-flex justify-content-center">
                <img
                  style={{ height: '120px' }}
                  src="/assets/2k_logo.png"
                  alt="CompanyLogo"
                />
              </div>
            </div>
            <div className="col-12 d-flex flex-column justify-content-center align-items-center">
              <div className="col-11 p-3 newBooking_ticketDetail_first">
                <div className="col-12 ">
                  <div className="col-12 d-flex">
                    <div className="col-6 newBooking_ticketText">
                      Customer Name
                    </div>
                    <div className="col-6 newBooking_ticketText">
                      {invoiceDetails?.customername}
                    </div>
                  </div>
                  <div className="col-12 d-flex">
                    <div className="col-6 newBooking_ticketText">
                      Contact Number
                    </div>
                    <div className="col-6 newBooking_ticketText">
                      {invoiceDetails?.customermobilenumber}
                    </div>
                  </div>
                  <div className="col-12 d-flex">
                    <div className="col-6 newBooking_ticketText">EMail-ID</div>
                    <div className="col-6 newBooking_ticketText">
                      {invoiceDetails?.customermailid}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 d-flex flex-column justify-content-center align-items-center">
              <div className="col-11 p-3 newBooking_ticketDetail_first">
                <div className="col-12 ">
                  <div className="col-12 d-flex">
                    <div className="col-6 newBooking_ticketText">
                      Location Name
                    </div>
                    <div className="col-6 newBooking_ticketText">
                      {invoiceDetails?.ticketname}
                    </div>
                  </div>
                  <div className="col-12 d-flex">
                    <div className="col-6 newBooking_ticketText">
                      Adult Ticket
                    </div>
                    <div className="col-6 newBooking_ticketText">
                      {invoiceDetails?.adulttickets}
                    </div>
                  </div>
                  <div className="col-12 d-flex">
                    <div className="col-6 newBooking_ticketText">
                      Kids Ticket
                    </div>
                    <div className="col-6 newBooking_ticketText">
                      {invoiceDetails?.kidstickets}
                    </div>
                  </div>
                  <div className="col-12 d-flex">
                    <div className="col-6 newBooking_ticketText">
                      Ticket Count
                    </div>
                    <div className="col-6 newBooking_ticketText">
                      {invoiceDetails?.nooftickets}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 d-flex flex-column justify-content-center align-items-center">
              <div className="col-11 p-3 newBooking_ticketDetail_first">
                <div className="col-12 ">
                  <div className="col-12 d-flex">
                    <div className="col-6 newBooking_ticketText">Date</div>
                    <div className="col-6 newBooking_ticketText">
                      {invoiceDetails?.date?.toLocaleDateString()}
                    </div>
                  </div>
                  <div className="col-12 d-flex">
                    <div className="col-6 newBooking_ticketText">Slot</div>
                    <div className="col-6 newBooking_ticketText">
                      {invoiceDetails?.slot}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 d-flex flex-column justify-content-center align-items-center">
              <div
                className="col-11 p-3 newBooking_ticketDetail_first"
                style={{ border: 'none' }}
              >
                <div className="col-12 ">
                  <div className="col-12 d-flex">
                    <div className="col-6 newBooking_ticketText">
                      Adult Ticket
                    </div>
                    <div className="col-6 newBooking_ticketText">{invoiceDetails?.adultticketprice}</div>
                  </div>
                  <div className="col-12 d-flex">
                    <div className="col-6 newBooking_ticketText">
                      Kids Ticket
                    </div>
                    <div className="col-6 newBooking_ticketText">{invoiceDetails?.kidsticketprice}</div>
                  </div>
                  <div className="col-12 d-flex">
                    <div className="col-6 newBooking_ticketText">
                      Ticket Amount
                    </div>
                    <div className="col-6 newBooking_ticketText">5500</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 py-2 d-flex justify-content-around">
              <button className="newBooking_slotSubmit_btn " type="submit">
                Next
              </button>
            </div>
          </div>
        </div>
      </TicketSlotModal>
      <div className=""> </div>
    </section>
  )
}
