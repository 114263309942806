import React from 'react'

const InputField = (props) => {
  return (
    <>
      <input
        className={`${props?.loginInputCss}
         ${props?.addTicketInputCss} 
         ${props?.addUserInputCss} 
         ${props?.addTicketDescriptionInputCss}
         ${props?.addTicketUploadImageInputCss}
         ${props?.addUserInputCss}
         ${props?.addsubticketInputField} 
         ${props?.addsubticketImageInputField}
         ${props?.newBookingInputCss} 
         ${props?.newBookingTicketSlotInputCss} 
         ${props?.editPageInputField}
         ${props?.verificationInputField}
         ${props?.editComboInputCss}
         ${props?.comboInputField}
         ${props?.comboPriceInputField}
         ${props?.bookingEditInput}`}
        type={props.type}
        // checked={props.checked}
        placeholder={props.placeholder}
        {...props.field}
        style={
          props?.fieldState && props?.fieldState?.error
            ? { borderColor: 'red' }
            : {}
        }
        list={props.list}
      ></input>
    </>
  )
}

export default InputField
