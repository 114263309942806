import React, { useEffect, useState } from 'react'
import Sidebars from '../components/Sidebars'
import { Controller, useForm } from 'react-hook-form'
import InputField from '../components/InputField'
import PopUpModal from '../components/PopUpModal'
import { addComboPostMethod, viewSubTicketGetMethod } from '../globalApi/Api'
import { useMutation, useQuery } from 'react-query'

const AddCombo = () => {
  const { control, handleSubmit, watch, reset } = useForm()
  const [addSubTicketPopUp, setAddSubTicketPopUp] = useState(null)
  const [comboSelect, setComboSelect] = useState([])
  const [ticketCategory, setTicketCategory] = useState('adult')

  const { selectcombo } = watch()
  const comboChange = comboSelect.join(', ')
  console.log('comboChange', comboChange)

  useEffect(() => {
    setComboSelect([...comboSelect, selectcombo])
  }, [selectcombo])

  const { data, isLoading, isError, isRefetching } = useQuery(
    'viewSubTicket',
    viewSubTicketGetMethod,
  )

  // const selectComboList = data && sdata?.data

  const selectComboList = data&&data?.data
   
  const { mutate, isSuccess } = useMutation(
    (postData) => addComboPostMethod(postData),
    {
      onSuccess: (data) => {
        console.log('Data_formate', data?.data)
        // reset();
        setAddSubTicketPopUp(1)
        window.location.reload()
      },
      onError: (error) => {
        console.error('Mutation failed:', error)
      },
    },
  )

  const Submit = (data) => {

    let value = {
        comboname: data?.comboname,
        selectcombo: comboChange,
        actualprice: data?.actualprice,
        discountprice:data?.discountprice,
        totalprice: data?.totalprice,
        description: data?.description,
        categories: ticketCategory
    }

    mutate(value)
  };
  return (
    <>
      <section className="container-fluid ps-0 addTicket_mainContainer">
        <div className="container my-2">
          <div className="row">
            <header className="col-lg-11 mx-5 px-0 bg-light addTicket_innerContainer_header">
              <div className=" p-4 addTicket_headed_text ">ADD COMBO</div>
            </header>
            <div className="col-lg-11 mx-5 py-2 px-5  addTicket_inputField_container ">
              <form
                style={{ padding: '0px 100px' }}
                onSubmit={handleSubmit(Submit)}
              >
                <div className=" d-flex py-3 align-items-center gap-3">
                  <label className="col-3 editComboLabel">
                    Enter a Combo Name
                  </label>
                  <Controller
                    control={control}
                    name="comboname"
                    render={(field) => (
                      <InputField
                        comboInputField="editCombo_selectCombo"
                        {...field}
                        type="text"
                      />
                    )}
                  />
                </div>

                <div className="d-flex py-3 align-items-center gap-3">
                  <div className="col-12 d-flex">
                    <div className="col-3 editComboLabel">Select Combo</div>
                    <Controller
                      name="selectcombo"
                      control={control}
                      // rules={{ required: "Category is required" }}
                      render={({ field }) => (
                        <select
                          {...field}
                          id="category"
                          style={{ marginLeft: '17px' }}
                          className="editCombo_selectCombo"
                        >
                          {selectComboList?.map((data) => {
                            return (
                              <>
                                {/* <option> </option> */}
                                <option value={data?.ticketname}>
                                  {data?.ticketname}
                                </option>
                              </>
                            )
                          })}
                          {/* <option value="">--SELECT COMBO--</option>
                        <option value="TICKET_NAME_1">TICKET_NAME_1</option>
                        <option value="TICKET_NAME_2">TICKET_NAME_2</option>
                        <option value="TICKET_NAME_3">TICKET_NAME_3</option> */}
                        </select>
                      )}
                    />
                    <div
                      className=" my-2 d-flex justify-content-around"
                      style={{ width: '30%', marginLeft: '20px' }}
                    >
                      <div className="col-2 d-flex justify-content-end">
                        <Controller
                          control={control}
                          name="categories"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <input
                              onClick={() => {
                                setTicketCategory('adult')
                              }}
                              type="checkBox"
                              checked={ticketCategory === 'adult'}
                              className="addCombo_checkBox_inputFields"
                              onChange={onChange}
                              defaultValue={false}
                            />
                          )}
                          // rules={{ required: true }}
                        />
                        <label>Adult</label>
                      </div>
                      <div className="col-2 d-flex justify-content-end">
                        <Controller
                          control={control}
                          name="category"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <input
                              onClick={() => {
                                setTicketCategory('kids')
                              }}
                              onChange={onChange}
                              type="checkBox"
                              checked={ticketCategory === 'kids'}
                              className="addCombo_checkBox_inputFields"
                              defaultValue={false}
                            />
                          )}
                          // rules={{ required: true }}
                        />
                        <label>Kids</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" my-2 d-flex editComboSelectBox">
                  {/* <div className="d-flex " style={{ width: "100px" }}> */}
                  {comboSelect.map((data, index) => {
                    return (
                      <div
                        key={index}
                        // style={{ height: "40px" }}
                        className="p-2 "
                      >
                        {data}
                      </div>
                    )
                  })}
                  {/* comboSelect} */}
                  {/* </div> */}
                </div>
                <div className=" d-flex py-3 align-items-center gap-3">
                  <label className="col-3 editComboLabel">Description</label>
                  <Controller
                    control={control}
                    name="description"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <textarea
                        style={{
                          width: '80%',
                          padding: '10px',
                          height: '100px',
                        }}
                        value={value}
                        onChange={onChange}
                        className="editCombo_selectCombo"
                      ></textarea>
                    )}
                  />
                </div>
                <div className="col-12  pb-2">
                  <div className="d-flex justify-content-end pb-3 ">
                    <label className="col-2 editComboLabel">Actual Price</label>
                    <Controller
                      control={control}
                      name="actualprice"
                      render={(field) => (
                        <InputField
                          comboPriceInputField="editComboPriceInputField"
                          {...field}
                          type="number"
                        />
                      )}
                    />
                  </div>
                  <div className="d-flex justify-content-end pb-3">
                    <label className="col-2 editComboLabel">
                      Discount Price
                    </label>
                    <Controller
                      control={control}
                      name="discountprice"
                      render={(field) => (
                        <InputField
                          comboPriceInputField="editComboPriceInputField"
                          {...field}
                          type="number"
                        />
                      )}
                    />
                  </div>
                  <div className="d-flex justify-content-end pb-3">
                    <label className="col-2 editComboLabel">Total Price</label>
                    <Controller
                      control={control}
                      name="totalprice"
                      render={(field) => (
                        <InputField
                          comboPriceInputField="editComboPriceInputField"
                          {...field}
                          type="number"
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-end pb-3">
                  <button className="editCombo_submitBtn" type="submit">
                    {' '}
                    SUBMIT
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <PopUpModal isOpen={addSubTicketPopUp === null}>
        <div className="popup-overlay">
          <div className="confirmation-popup">
            <div style={{ height: '120px' }}>
              <img
                src="/assets/edit_back.jpg"
                alt="no-image"
                className="p-0 m-0 popUpBackGround"
              ></img>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <span className="popUpContent ">Thank you!</span>
              <span className="popUpContentPara py-3">
                New combo has been added successfully.
              </span>
              <button
                onClick={() => {
                  setAddSubTicketPopUp(null)
                }}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </PopUpModal>
    </>
  )
}

export default AddCombo
