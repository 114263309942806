import React, { useState } from 'react'
import Sidebars from '../components/Sidebars'
import { Controller, useForm } from 'react-hook-form'
import InputField from '../components/InputField'
import PopUpModal from '../components/PopUpModal'
import axios from 'axios'
import { useMutation } from 'react-query'
import { addUserPostMethod } from '../globalApi/Api'
// import { addUser as addUserReducer } from '../redux/slices/logindetailsslice'
import { addUsers as addUserReducer } from '../redux/slices/logindetailsslice'
import { useDispatch, useSelector } from 'react-redux'

export default function AddUser() {
  const { control, handleSubmit, setValue, reset, watch } = useForm()
  const [addTicketPopUp, setAddTicketPopUp] = useState(null)
  const [base64Image, setBase64Image] = useState(null)
  const dispatch = useDispatch()

  // const { mutate, isLoading, isError, isSuccess } = useMutation(
  //   (postData) => addUserPostMethod(postData),
  //   {
  //     onSuccess: (data) => {
  //       console.log('Data_formate', data&&data?.data)
  //
  //       // reset()
  //       setAddTicketPopUp(1)
  //     },
  //     onError: (error) => {
  //       console.error('Mutation failed:', error)
  //     },
  //   },
  // )
  const { mutate, isLoading, isError, isSuccess } = useMutation(
    // First argument: function that makes the actual request
    // Second argument (optional): Config object
    (postData) =>
      axios.post(
        `https://api.2kvirtualworld.com/ticketBook/addusers`,
        postData,
      ),
    {
      // You can define callbacks for different stages of the mutation
      onSuccess: (userData) => {
        // Handle successful mutation
        console.log('userData', userData && userData?.data)
        const newUser = [{
          id: userData && userData?.data?.id,
          companyname: userData && userData?.data?.companyname,
          emailid: userData && userData?.data?.emailid,
          mobilenumber: userData && userData?.data?.mobilenumber,
          role: userData && userData?.data?.role,
        }]

        dispatch(addUserReducer(newUser))
        

        setAddTicketPopUp(1)
      },
      onError: (error) => {
        // Handle error
        console.error('Mutation failed:', error)
      },
    },
  )

  const handleImageInputChange = (event) => {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        const base64String = reader.result
        const base64LetterRemove = base64String.substring(
          base64String.indexOf(',') + 1,
        )
        setBase64Image(base64LetterRemove)
      }
      reader.onerror = (error) => {
        console.error('Error occurred while converting image to base64:', error)
      }
    }
  }

  const { password, confirmpassword } = watch()

  const Submit = (data) => {
    console.log('data', data)
    // setAddTicketPopUp(1);
    // dispatch(addUserReducer(data?.emailid))
    const value = {
      firstname: data?.firstname,
      lastname: data?.lastname,
      mobilenumber: data?.mobilenumber,
      alternatenumber: data?.alternatenumber,
      companyname: data?.companyname,
      address: data?.address,
      companytype: data?.companytype,
      role: data?.role,
      emailid: data?.emailid,
      password: data?.password,
      confirmpassword: data?.confirmpassword,
      companylogo: base64Image,
    }
    if (password === confirmpassword) {
      mutate(value)
    }
  }
  const users = useSelector((state) => state.loginDetails.users)
  console.log('USERS', users)
  return (
    <section className="container-fluid ps-0 addTicket_mainContainer">
      {/* <Sidebars /> */}

      <div className="container">
        <div className="row">
          <header className="col-11 mx-5 mt-3  px-0 bg-light addTicket_innerContainer_header">
            <div className=" p-4 addTicket_headed_text ">ADD USER</div>
          </header>
          <div className="col-11 mx-5 px-4 py-3 addTicket_inputField_container">
            <div className="pb-2">
              <label className="addUser_ticket_label">User Details </label>
            </div>
            <form onSubmit={handleSubmit(Submit)}>
              <div className=" col-12 my-2 d-flex ">
                <div className="col-6 ">
                  <label className="col-5 addUser_ticket_label">
                    First Name
                  </label>
                  <Controller
                    control={control}
                    name="firstname"
                    render={(field) => (
                      <InputField
                        addUserInputCss="--addUser_inputFields"
                        {...field}
                        type="text"
                      />
                    )}
                    // rules={{ required: true }}
                  />
                </div>
                <div className="col-6 ">
                  <label className="col-5 addUser_ticket_label">
                    Last Name
                  </label>
                  <Controller
                    control={control}
                    name="lastname"
                    render={(field) => (
                      <InputField
                        // style = {{margin}}
                        addUserInputCss="--addUser_inputFields"
                        {...field}
                        type="text"
                      />
                    )}
                    // rules={{ required: true }}
                  />
                </div>
              </div>
              <div className="col-12 my-2 d-flex">
                <div className="col-6 ">
                  <label className=" col-5  addUser_ticket_label">
                    Mobile No
                  </label>

                  <Controller
                    control={control}
                    name="mobilenumber"
                    render={(field) => (
                      <InputField
                        addUserInputCss="--addUser_inputFields"
                        {...field}
                        type="number"
                      />
                    )}
                    // rules={{ required: true }}
                  />
                </div>
                <div className="col-6 ">
                  <label className=" col-5  addUser_ticket_label">
                    Alternate No
                  </label>

                  <Controller
                    control={control}
                    name="alternatenumber"
                    render={(field) => (
                      <InputField
                        addUserInputCss="--addUser_inputFields"
                        {...field}
                        type="number"
                      />
                    )}
                    // rules={{ required: true }}
                  />
                </div>
              </div>
              <div className="col-12 my-2">
                <div className="d-flex">
                  <label
                    style={{ width: '20%' }}
                    className="addUser_ticket_label">
                    Company Name
                  </label>
                  <Controller
                    control={control}
                    name="companyname"
                    render={(field) => (
                      <InputField
                        // style = {{margin}}
                        addUserInputCss=" col-9 --addUser_companyName_inputField"
                        {...field}
                        type="text"
                      />
                    )}
                    // rules={{ required: true }}
                  />
                </div>
              </div>
              <div className=" col-12 my-2">
                <div className="d-flex">
                  <label
                    style={{ width: '20%' }}
                    className=" addUser_companyAdd_label">
                    Company Address
                  </label>
                  <Controller
                    control={control}
                    name="address"
                    render={(field) => (
                      <InputField
                        addUserInputCss="--addUser_companyName_inputField"
                        {...field}
                        type="text"
                      />
                    )}
                    // rules={{ required: true }}
                  />
                </div>
              </div>
              <div className=" col-12 my-2 d-flex">
                <div className="col-6 ">
                  <label className="col-5 addUser_ticket_label">
                    Company Type
                  </label>
                  <Controller
                    control={control}
                    name="companytype"
                    render={(field) => (
                      <InputField
                        // style = {{margin}}
                        addUserInputCss=" --addUser_inputFields"
                        {...field}
                        type="text"
                      />
                    )}
                    // rules={{ required: true }}
                  />
                </div>
                <div className="col-6 d-flex">
                  <label className="col-5 addUser_ticket_label">
                    Company logo
                  </label>
                  <div className="  addUser_logoSelect_inputContainer">
                    <Controller
                      control={control}
                      name="companylogo"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <input
                          // style={{ display: "none" }}
                          className=" col-10 addUser_logoSelect_input"
                          accept="image/*"
                          type="file"
                          onChange={(e) => onChange(handleImageInputChange(e))}
                        />
                      )}
                      // rules={{ required: true }}
                    />
                    <img
                      style={{
                        height: '20px',
                        width: '25px',
                        backgroundColor: '',
                      }}
                      src="/assets/addImage.png"
                      alt="addImage"
                    />
                  </div>
                </div>
              </div>
              <div className=" col-12 my-2 d-flex">
                <div className="col-6 ">
                  <label className="col-5 addUser_ticket_label">Role</label>
                  <Controller
                    control={control}
                    name="role"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        value={value}
                        onChange={onChange}
                        className="--addUser_inputFields"
                        style={{ 'text-transform': 'capitalize' }}>
                        <option> </option>
                        <option value="admin">Admin</option>
                        <option value="limitedAdmin">Limited Admin</option>
                        <option value="superAdmin">Super Admin</option>
                      </select>
                    )}
                  />
                </div>
                <div className="col-6 ">
                  <label className="col-5 addUser_ticket_label">Email ID</label>
                  <Controller
                    control={control}
                    name="emailid"
                    render={(field) => (
                      <InputField
                        {...field}
                        addUserInputCss="--addUser_inputFields"
                        type="email"
                      />
                    )}
                    // rules={{ required: true }}
                  />
                </div>
              </div>
              <div className=" col-12 my-2 d-flex">
                <div className="col-6">
                  <label className="col-5 addUser_ticket_label">Password</label>
                  <Controller
                    control={control}
                    name="password"
                    render={(field) => (
                      <InputField
                        {...field}
                        addUserInputCss="--addUser_inputFields"
                        type="password"
                      />
                    )}
                  />
                </div>
                <div className="col-6">
                  <label className="col-5 addUser_ticket_label">
                    Confirm Password{' '}
                  </label>
                  <Controller
                    control={control}
                    name="confirmpassword"
                    render={(field) => (
                      <InputField
                        {...field}
                        addUserInputCss="--addUser_inputFields"
                        type="password"
                      />
                    )}
                  />
                </div>
              </div>

              <div className="col-12 addUser_submitBtn_field ">
                <button className=" col-5 addUser_submitBtn" type="submit">
                  {' '}
                  SUBMIT
                </button>
              </div>
            </form>
          </div>
        </div>
        <PopUpModal isOpen={addTicketPopUp === null}>
          <div className="popup-overlay">
            <div className="confirmation-popup">
              <div style={{ height: '120px' }}>
                <img
                  src="/assets/edit_back.jpg"
                  alt="backgroundImg"
                  className="p-0 m-0 popUpBackGround"
                />
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <span className="popUpContent ">Thank you!</span>
                <span className="popUpContentPara py-3">
                  User has been added successfully
                </span>
                <button
                  onClick={() => {
                    setAddTicketPopUp(null)
                  }}>
                  OK
                </button>
              </div>
            </div>
          </div>
        </PopUpModal>
      </div>
    </section>
  )
}
