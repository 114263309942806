import axios from 'axios'

export async function addTicketPostMethod(postData) {
  console.log('API', postData)
  axios.post(`https://api.2kvirtualworld.com/ticketBook/addtickets`, postData)
}

export async function addSubTicketPostMethod(postData) {
  console.log("API", postData);
  axios.post(`https://api.2kvirtualworld.com/ticketBook/addsubtickets`,postData);
}

// export async function addUserPostMethod(postData) {
//   console.log('API', postData)
//   axios.post(`https://api.2kvirtualworld.com/ticketBook/addusers`, postData)
// }

export async function addNotificationPostMethod(postData) {
  console.log('API', postData)
  axios.post(`https://api.2kvirtualworld.com/ticketBook/addtickets`, postData)
}

export async function addComboPostMethod(postData) {
  console.log('API', postData)
  axios.post(`https://api.2kvirtualworld.com/ticketBook/addcombo`, postData)
}
export async function newBookingPostMethod(postData) {
  console.log("API", postData);
  axios.post(`https://api.2kvirtualworld.com/booktickets/bookings`, postData);
}

export const viewComboGetMethod = async () => {
  return await axios.get('https://api.2kvirtualworld.com/ticketBook/addcombo')
}

export const viewSubTicketGetMethod = async () => {
  return await axios.get("https://api.2kvirtualworld.com/ticketBook/addsubtickets");
};
export const getBookingTable = async () => {
  return await axios.get('https://api.2kvirtualworld.com/booktickets/bookings')
}
export const getOTP = async () => {
  return await axios.get(
    `https://api.2kvirtualworld.com/WebUserManagement-0.0.1-SNAPSHOT/addusers/sendMail/`,
  )
}
export const getBookingVerification = async () => {
  return await axios.get(
    `https://api.2kvirtualworld.com/booktickets/bookings/verify-otp?otp=695443`,
  )
}
export const getViewSubTicketDetails = async () => {
  return await axios.get(
    `https://api.2kvirtualworld.com/ticketBook/addsubtickets`,
  )
}
export const getViewTicketDetails = async () => {
  return await axios.get(`https://api.2kvirtualworld.com/ticketBook/addtickets`)
}

export async function viewComboDeleteMethod(itemId) {
  return await await axios.delete(
    `https://api.2kvirtualworld.com/ticketBook/addcombo/${itemId?.id}`,
  )
}

// export async function addTicketPostMethod  ({postData})  {
//     console.log('API',postData);
//    await (
//         `https://api.2kvirtualworld.com/ticketBook/addtickets`,
//         postData
//     )
//     };
// export const getBookingTable = async () => {
//     return await axios.get(
//      `https://api.2kvirtualworld.com/booktickets/bookings`
//     );
//   };
export const getOtpVerification = async () => {
  return await axios.get(
    `https://api.2kvirtualworld.com/booktickets/bookings/verify-otp?otp=695443`,
  )
}
export const getAdultTicketCounts = async () => {
  return await axios.get('https://api.2kvirtualworld.com/booktickets//bookings/sumofadulttickets')
}
export const getChildTicketCounts = async () => {
  return await axios.get('https://api.2kvirtualworld.com/booktickets//bookings/sumofadulttickets')
}
export const getAdultTicketDateCount = async () => {
  return await axios.get('https://api.2kvirtualworld.com/booktickets/bookings/sum-of-adultticketsbydate?date=2024-07-17')
}
export const getChildTicketDateCount = async () => {
  return await axios.get('https://api.2kvirtualworld.com/booktickets/bookings/sumofkidsticketsbydate')
}


//   export async function newBooking  ({postData})  {
//     console.log('API',postData);
//    await (
//         `https://api.2kvirtualworld.com/ticketBook/addtickets`,
//         postData
//     )
//     };
// "id": 6,
//         "comboname": null,
//         "selectcombo": ",TICKET_NAME_1,TICKET_NAME_2,TICKET_NAME_3,TICKET_NAME_2,,,,",
//         "actualprice": null,
//         "discountprice": null,
//         "totalprice": null,
//         "description": null,
//         "categories": "kids"
{
}
