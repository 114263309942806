import React, { useState } from 'react'
import Sidebars from '../components/Sidebars'
import { Controller, useForm } from 'react-hook-form'
import InputField from '../components/InputField'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import PopUpModal from '../components/PopUpModal'
import { useQuery } from 'react-query'
import { getBookingVerification } from '../globalApi/Api'
import axios from 'axios'

export const fetchPostsByUser = async (val) => {
  try {
    const response = await axios.get(
      `https://api.2kvirtualworld.com/booktickets/bookings/verify-otp?`,
      {
        params: { otp: val }, // Pass userId as a query parameter
      },
    )
    return response.data
  } catch (error) {
    throw new Error('Error fetching posts')
  }
}

const BookingVerification = () => {
  const { control, handleSubmit } = useForm()
  const tableData = [
    {
      id: 1,
      customerName: 'Prasanth',
      location: 'MGM',
      adultCount: 2,
      childCount: 2,
      date: '06-MAY-2024',
      slot: '9AM',
    },
  ]
  const navigate = useNavigate()
  const [showTable, setShowTable] = useState(false)
  const [addSubTicketPopUp, setAddSubTicketPopUp] = useState(null)
  const [isotp, setIsOtp] = useState('')
  const [isEnabled, setisEnabled] = useState(false)

  const { data, isLoading, isError } = useQuery(
    ['posts', isotp],
    () => fetchPostsByUser(isotp),
    {
      enabled: isEnabled,
      onSuccess: (userData) => {
        console.log('userData', userData)
        setisEnabled(false)
        setShowTable(true)
      },
      onerror: (error) => {
        console.log('Showing error message', error)
        setisEnabled(false)
      },
    },
  )

  console.log('otpverify', data && data)

  const onSubmit = (formdata) => {
    setisEnabled(true)
    console.log('data', formdata)
    setIsOtp(formdata?.otp)
  }
  return (
    <>
      <section className="container-fluid ps-0 addTicket_mainContainer">
        <div className="container  my-2">
          <div className="row">
            <header className="col-11  mx-5   px-0 bg-light addTicket_innerContainer_header">
              <div className=" p-4 addTicket_headed_text ">
                BOOKING VERIFICATION
              </div>
            </header>
            {/* </div> */}
            {/* </div> */}
            {/* <div className="row verification_form_block"> */}
            <div className="col-lg-11 mx-5 py-5 verification_form_block">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="verificationInputBlock">
                  <Controller
                    control={control}
                    name="otp"
                    render={(field) => (
                      <InputField
                        verificationInputField="verificationInputField"
                        {...field}
                        type="number"
                        placeholder="Enter the OTP"
                      />
                    )}
                    // rules={{ required: true }}
                  />
                  <button className="verificationButton" onClick={() => {}}>
                    VERIFY
                  </button>
                </div>
              </form>
              <div className="table-responsive px-4 pt-5 pb-3">
                <TableContainer>
                  <Table>
                    <TableHead className="bookingtableheading">
                      <TableRow>
                        <TableCell className="bookingtablehead">
                          Customer Name
                        </TableCell>
                        <TableCell className="bookingtablehead">
                          Location
                        </TableCell>
                        <TableCell className="bookingtablehead">
                          Adult Count
                        </TableCell>
                        <TableCell className="bookingtablehead">
                          Child Count
                        </TableCell>
                        <TableCell className="bookingtablehead">Date</TableCell>
                        <TableCell className="bookingtablehead">Slot</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                      sx={{ marginTop: '20px' }}
                      onClick={() => {
                        navigate('/bookingManagementEditPage')
                      }}>
                      {showTable && (
                        <>
                          {Array(data && data).map((item, i) => {
                            return (
                              <>
                                <TableRow>
                                  <TableCell className="verificationTableData">
                                    {item?.customername}
                                  </TableCell>
                                  <TableCell className="verificationTableData">
                                    {item?.ticketname}
                                  </TableCell>
                                  <TableCell className="verificationTableData">
                                    {item?.adulttickets}
                                  </TableCell>
                                  <TableCell className="verificationTableData">
                                    {item?.kidstickets}
                                  </TableCell>
                                  <TableCell className="verificationTableData">
                                    {item?.date}
                                  </TableCell>
                                  <TableCell className="verificationTableData">
                                    {item?.slot}
                                  </TableCell>
                                </TableRow>
                              </>
                            )
                          })}
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              {showTable && (
                <>
                  <div className="d-flex justify-content-end gap-3 px-4">
                    <button
                      className="verificationButton"
                      onClick={() => {
                        setAddSubTicketPopUp(1)
                      }}>
                      Download
                    </button>
                    <button
                      className="verificationButton"
                      onClick={() => {
                        setShowTable(false)
                      }}>
                      Verified
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div />
      </section>
      <PopUpModal isOpen={addSubTicketPopUp === null}>
        <div className="popup-overlay">
          <div className="confirmation-popup">
            <div style={{ height: '120px' }}>
              <img
                src="/assets/edit_back.jpg"
                alt="no-image"
                className="p-0 m-0 popUpBackGround"></img>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <span className="popUpContent ">Thank you!</span>
              <span className="popUpContentPara py-3">
                Ticket has been downloaded successfully.
              </span>
              <button
                onClick={() => {
                  setAddSubTicketPopUp(null)
                }}>
                OK
              </button>
            </div>
          </div>
        </div>
      </PopUpModal>
    </>
  )
}

export default BookingVerification
