import React, { useEffect, useState } from 'react'
import Sidebars from '../components/Sidebars'
import { Controller, useForm } from 'react-hook-form'
import InputField from '../components/InputField'
import { useSelector } from 'react-redux'

export default function Trending() {
  const { control, handleSubmit, watch } = useForm()
  const [comboSelect, setComboSelect] = useState([])
  const users = useSelector(state => state.loginDetails?.users);
  console.log("users",users)
  const { category } = watch()
  const comboChange = comboSelect.join(', ')
  console.log('comboChange', comboChange)

  useEffect(() => {
    setComboSelect([...comboSelect, category])
  }, [category])

  const Submit = (data) => {
    console.log('Data', data)
    alert('Data added successfully..')
  }

  return (
    <>
      <section className="container-fluid ps-0 addTicket_mainContainer">
        {/* <Sidebars /> */}

        <div className="container my-2">
          <div className="row">
            <header className="col-lg-11 mx-5 px-0 bg-light addTicket_innerContainer_header">
              <div className=" p-4 addTicket_headed_text ">TRENDING</div>
            </header>
            <div className="col-lg-11 mx-5 py-2 addTicket_inputField_container ">
              <form
                style={{ padding: '0px 100px', height: '83vh' }}
                onSubmit={handleSubmit(Submit)}
              >
                <div className=" d-flex py-3 align-items-center gap-3">
                  <label className="col-2 comboInputLabel">
                    Enter a Trending Name
                  </label>
                  <Controller
                    control={control}
                    name="comboName"
                    render={(field) => (
                      <InputField
                        comboInputField="comboInputField"
                        {...field}
                        type="text"
                      />
                    )}
                  />
                </div>
                <div className="d-flex py-3 align-items-center gap-3">
                  <div className="col-2 comboInputLabel">Select trending</div>
                  <Controller
                    name="category"
                    control={control}
                    rules={{ required: 'Category is required' }}
                    render={({ field }) => (
                      <select
                        {...field}
                        id="category"
                        className="editCombo_selectCombo"
                      >
                        <option value="">--SELECT COMBO--</option>
                        <option value="TICKET_NAME_1">TICKET_NAME_1</option>
                        <option value="TICKET_NAME_2">TICKET_NAME_2</option>
                        <option value="TICKET_NAME_3">TICKET_NAME_3</option>
                      </select>
                    )}
                  />
                </div>
                <div className=" my-2 d-flex editComboSelectBox">
                  {/* <div className="d-flex " style={{ width: "100px" }}> */}
                  {comboSelect.map((data, index) => {
                    return (
                      <div
                        key={index}
                        // style={{ height: "40px" }}
                        className="p-2 "
                      >
                        {data}
                      </div>
                    )
                  })}
                  {/* comboSelect} */}
                  {/* </div> */}
                </div>
                <div className="mt-3 trending_submitBtn_field --model-filed ">
                  <div className="addTicket_ticket_label"></div>
                  <button
                    className="  addTicket_submitBtn --model"
                    type="submit"
                  >
                    {' '}
                    SUBMIT
                  </button>
                </div>
              </form>
            </div>
          </div>
          {/* <PopUpModal isOpen={addTicketPopUp === null}>
            <div className="popup-overlay">
              <div className="confirmation-popup">
                <div style={{ height: "120px" }}>
                  <img
                    src="/assets/edit_back.jpg"
                    alt="no-image"
                    className="p-0 m-0 popUpBackGround"
                  ></img>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <span className="popUpContent ">Thank you!</span>
                  <span className="popUpContentPara py-3">
                    Your ticket has been added successfully
                  </span>
                  <button
                    onClick={() => {
                      setAddTicketPopUp(null);
                    }}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </PopUpModal> */}
        </div>
      </section>
    </>
  )
}
