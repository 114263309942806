import React from 'react'
import { Bar } from 'react-chartjs-2'

const Barchart = () => {
    const data = {
        labels: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'Octomber',
          'November',
          'December',
        ],
        datasets: [
          {
            label: 'Tickets',
            data: [100, 59, 180, 81, 556, 55, 40, 500, 300, 1000, 400, 500],
            backgroundColor: ' #4959AA',
            borderColor: '#4959AA',
            borderWidth: 1,
          },
        ],
      }
      const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Ticket Count',
          },
        },
      }
  return (
 <>
  <Bar data={data} options={options} />
 </>
  )
}

export default Barchart