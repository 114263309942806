import React from 'react'

const TicketSlotModal = ({ isOpen, onClose, children }) => {
  if (isOpen) return null

  return (
    <div style={{
      width: "100%",
      minHeight:"900vh",
      // background: "#808080",
      paddingTop:"60px",
      margin: "auto",
      // display:"flex",
      // alignItems:"center"
    }}
      className="ticketSlotContainer"
    >
      <div
        style={{
          width: "30%",
          minHeight:"950vh",
          // background: "#808080",
          margin: "auto",
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default TicketSlotModal
