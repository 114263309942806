// import React, { useState } from 'react'
// import DatePicker from 'react-datepicker'
// import 'react-datepicker/dist/react-datepicker.css'

// function TableFilter() {
//   const [startDate, setStartDate] = useState(null)
//   const [endDate, setEndDate] = useState(null)

//   // Sample data for demonstration
//   const data = [
//     { id: 1, date: '2024-07-10', description: 'Item 1' },
//     { id: 2, date: '2024-07-12', description: 'Item 2' },
//     { id: 3, date: '2024-07-15', description: 'Item 3' },
//   ]

//   // Filter function
//   const filteredData = data.filter((item) => {
//     const itemDate = new Date(item.date)
//     return (
//       (!startDate || itemDate >= startDate) && (!endDate || itemDate <= endDate)
//     )
//   })

//   return (
//     <>
//       <section className="main_Section">
//         <div className="date-picker-container">
//           {/* Date picker components */}
          

          

        
//           {/* Table component with filtered data */}
//           <table className="data-table">
//             <thead>
//               <tr>
//                 <th>ID</th>
//                 <th>Date</th>
//                 <th>Description</th>
//               </tr>
//             </thead>
//             <tbody>
//               {filteredData?.map((item) => (
//                 <tr key={item.id}>
//                   <td>{item.id}</td>
//                   <td>{item.date}</td>
//                   <td>{item.description}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//         <div className='container'>
//           <div className='row py-3'>
//           <div className="col-lg-12 d-flex align-items-center justify-content-end gap-2" >
//             <div className="d-flex align-items-center gap-2 justify-content-end">
//               <label className="startDate">START</label>
//               <div className="d-flex align-items-center startDatePicker px-2">
//                 <DatePicker
//                   selected={startDate}
//                   onChange={(date) => setStartDate(date)}
//                   selectsStart
//                   startDate={startDate}
//                   endDate={endDate}
//                   placeholderText="Start date"
//                   className="startDatePickers"
//                 />
//                 <img src="/assets/Subtract.svg" />
//               </div>
//             </div>
//             <div className="d-flex align-items-center gap-2">
//               <label className="startDate">END</label>
//               <div className="d-flex align-items-center startDatePicker px-2">
//                 <DatePicker
//                   selected={endDate}
//                   onChange={(date) => setEndDate(date)}
//                   selectsEnd
//                   startDate={startDate}
//                   endDate={endDate}
//                   minDate={startDate}
//                   placeholderText="End date"
//                   className="startDatePickers"
//                 />
//                 <img src="/assets/Subtract.svg" />
//               </div>
//             </div>
//           </div>
//           </div>

//         </div>
//       </section>
//     </>
//   )
// }

// export default TableFilter
// import './App.css';
import {useEffect, useState} from 'react';
import axios from 'axios';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

function Example() {
  const [products, setProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [startDate,setStartDate]= useState(new Date());
  const [endDate,setEndDate]= useState(new Date());

  useEffect(()=>{
    axios.get("https://63bb90b3cf99234bfa5e3b48.mockapi.io/Products")
    .then((response)=>{
      setProducts(response.data);
      setAllProducts(response.data);
    })

  },[])

  const handleSelect = (date) =>{
    let filtered = allProducts.filter((product)=>{
      let productDate = new Date(product["createdAt"]);
      return(productDate>= date.selection.startDate &&
        productDate<= date.selection.endDate);
    })
    setStartDate(date.selection.startDate);
    setEndDate(date.selection.endDate);
    setProducts(filtered);
  };

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: 'selection',
  }
  return (
    <div className="App">
      <header className="App-header">
      <DateRangePicker
        ranges={[selectionRange]}
        onChange={handleSelect}
      />
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Product</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            
              {products.map((product)=>{
                let date = new Date(product["createdAt"]);
                return(
                  <tr>
                    <td>{product["id"]}</td>
                    <td>{product["name"]}</td>
                    <td>{date.toLocaleDateString()}</td>
                  </tr>
                );
              })}
            
          </tbody>
        </table>
      </header>
    </div>
  );
}

export default Example;

