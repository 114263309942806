import React, { useEffect, useState } from 'react'
import Sidebars from '../components/Sidebars'
import { useNavigate } from 'react-router-dom'
import InputField from '../components/InputField'
import { Controller, useForm } from 'react-hook-form'
import TicketSlotModal from '../components/TicketSlotModal'
import PopUpModal from '../components/PopUpModal'
import ReactDatePicker from 'react-datepicker'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { useMutation } from 'react-query'
const BMEditTicket = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm()
  const navigate = useNavigate()
  const [bmEditPopUp, setBmEditPopUp] = useState(null)
  const [startDate, setStartDate] = useState()
  const { state } = useLocation()
  console.log('STATE', state)

  useEffect(() => {
    setValue('customerName', state?.customername)
    setValue('location', state?.ticketname)
    setValue('adultCount', state?.adulttickets)
    setValue('childCount', state?.kidstickets)
    setValue('date', state?.date)
    setValue('slot', state?.slot)
  }, [])
  const { mutate, isLoading, isError, isSuccess } = useMutation(
    (postdata) =>
      axios.put(
        `https://api.2kvirtualworld.com/booktickets/bookings/${state.id}`,
        postdata,
      ),
    {
      onSuccess: (val) => {
        console.log('data updated successfully..')
      },
      onError: (error) => {
        console.error('Mutation failed:', error)
      },
    },
  )
  const onSubmit = (data) => {
    console.log('Data', data)
    let formData
    state && state.id !== null
      ? (formData = {
          customername: data?.customerName,
          ticketname: data?.location,
          adulttickets: data?.adultCount,
          kidstickets: data?.childCount,
          date: data?.date,
          slot: data?.slot,
        })
      : (formData = {
          customername: data?.customerName,
          ticketname: data?.location,
          adulttickets: Number(data?.adultCount),
          kidstickets: Number(data?.childCount),
          date: data?.date,
          slot: data?.slot,
        })
    if (state && state?.id) {
      mutate(formData)
      setBmEditPopUp(1)
    } else {
      mutate(formData)
    }
    // alert("Data updated successfully..");
  }

  return (
    <>
      <section className="main_Section d-flex justify-content-center">
        {/* <Sidebars /> */}
        <div className="container mx-5 my-3  p-0">
          <div className="row booking_form_block p-0 m-0">
            <div className="col-lg-12 m-0 p-0">
              <div style={{ height: '270px' }}>
                <img
                  src="/assets/edit_back.jpg"
                  alt="no-image"
                  className="p-0 m-0 popUpBackGround"
                ></img>
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="p-0 m-0">
              <div className="col-lg-12 --model_label">
                <div className="col-3">
                  <label className="bookingeditlabel">Customer Name</label>
                </div>
                <div className="col-9">
                  <Controller
                    control={control}
                    name="customerName"
                    render={(field) => (
                      <InputField
                        bookingEditInput=" bookingEditInput"
                        {...field}
                        type="text"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-12 --model_label">
                <div className="col-3">
                  <label className="bookingeditlabel ">Location</label>
                </div>
                <div className="col-9">
                  <Controller
                    control={control}
                    name="location"
                    render={(field) => (
                      <InputField
                        bookingEditInput=" bookingEditInput"
                        {...field}
                        type="text"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-12 --model_label">
                <div className="col-3">
                  <label className="bookingeditlabel">Adult Count</label>
                </div>
                <div className="col-3">
                  <Controller
                    control={control}
                    name="adultCount"
                    render={(field) => (
                      <InputField
                        bookingEditInput=" bookingEditInput"
                        {...field}
                        type="number"
                      />
                    )}
                  />
                </div>
                <div className="col-3 px-3">
                  <label className="bookingeditlabel">Child Count</label>
                </div>
                <div className="col-3">
                  <Controller
                    control={control}
                    name="childCount"
                    render={(field) => (
                      <InputField
                        bookingEditInput=" bookingEditInput"
                        {...field}
                        type="number"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-12 --model_label">
                <div className="col-3">
                  <label className="bookingeditlabel">Date</label>
                </div>
                <div className="col-3">
                  <Controller
                    control={control}
                    name="date"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <ReactDatePicker
                        className=" bookingEditInput"
                        value={value}
                        dateFormat="dd/MM/yyyy"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                      />
                    )}
                  />
                </div>
                <div className="col-3 px-3">
                  <label className="bookingeditlabel">Slot</label>
                </div>
                <div className="col-3">
                  <Controller
                    control={control}
                    name="slot"
                    render={(field) => (
                      <InputField
                        bookingEditInput=" bookingEditInput"
                        {...field}
                        type="time"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-12 --model_label">
                <div className="col-3"></div>
                <div className="col-9 justify-content-center  d-flex">
                  <button className="bookingEditTicketSubmitButton">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <PopUpModal isOpen={bmEditPopUp === null}>
        <div style={{ height: '130px' }}>
          <img
            src="/assets/edit_back.jpg"
            alt="no-image"
            className="p-0 m-0 popUpBackGround"
          ></img>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center ">
          <span className="popUpContent ">Thank you!</span>
          <span className="popUpContentPara pt-3 pb-2">
            Your ticket has been updated successfully
          </span>

          <button
            onClick={() => {
              setBmEditPopUp(null)
              navigate('/bookingTable')
            }}
          >
            OK
          </button>
        </div>
      </PopUpModal>
    </>
  )
}

export default BMEditTicket
