import {
  Sidebar,
  SubMenu,
  Menu,
  MenuItem,
  //useProSidebar
} from 'react-pro-sidebar'
import { Link, useNavigate, useLocation } from 'react-router-dom'
// import companyLogo from '../../public/asset/2kLogo.png'
// import { useNavigate } from "react-router-dom";
import React, { useState } from 'react'
import BMEditPage from '../pages/BMEditPage'
import { useDispatch } from 'react-redux'
import { deleteUser } from '../redux/slices/logindetailsslice'
export default function Sidebars() {
  console.log('sidebar')
  const [openSubmenu, setOpenSubmenu] = useState(null)
  const location = useLocation()
  const navigate = useNavigate()
  const handleSubmenuClick = (submenu) => {
    setOpenSubmenu(submenu === openSubmenu ? null : submenu)
  }
  const dispatch = useDispatch()
  const logout = () => {
    sessionStorage.removeItem("login")
    // dispatch(deleteUser(null))
    // localStorage.removeItem("emailId");
    navigate('/')
    window.location.reload();

  }
  return (
    <div
      // className={`${
      //   location.pathname == '/' ||
      //   location.pathname == '/getOtp' ||
      //   location.pathname == '/otpEnter' ||
      //   location.pathname == '/reset'
      //     ? 'side_bar_none'
      //     : ''
      // }`}
    >
      <div className="sidebar_mainContainer">
        {/* <div className="row"> */}
        <Sidebar
          className="sidebar_container"
          // style={{ height: "", overflowY: "" }}
        >
          <div className="sidebar_companyLogo_field">
            <img
              className="sidebar_companyLogo_img"
              src="./assets/2k_logo.png"
              alt="Company Logo"
            />
          </div>

          <Menu className="sidebar_menu">
            <MenuItem className="sidebar_menuItem"
            component={<Link to="/dashboard" />}
            >DASHBOARD</MenuItem>
            <SubMenu
              open={openSubmenu === 'TICKET MANAGEMENT'}
              onClick={() => handleSubmenuClick('TICKET MANAGEMENT')}
              className="sidebar_subMenu"
              label="TICKET MANAGEMENT"
            >
              <MenuItem
                className="sidebar_subMenu_items"
                component={<Link to="/addTicket" />}
              >
                <div className="sidebar_subMenu_subHeading">ADD TICKET</div>
              </MenuItem>
              <MenuItem
                className="sidebar_subMenu_items"
                component={<Link to="/ticketView" />}
              >
                <div className="sidebar_subMenu_subHeading">VIEW TICKET</div>
              </MenuItem>
              <MenuItem
                className="sidebar_subMenu_items"
                component={<Link to="/addSubTicket" />}
              >
                <div className="sidebar_subMenu_subHeading">ADD SUB TICKET</div>
              </MenuItem>

              <MenuItem
                className="sidebar_subMenu_items"
                component={<Link to="/viewSubTicket" />}
              >
                <div className="sidebar_subMenu_subHeading">
                  VIEW SUB TICKET
                </div>
              </MenuItem>
            </SubMenu>
            <SubMenu
              open={openSubmenu === 'BOOKING'}
              onClick={() => handleSubmenuClick('BOOKING')}
              className="sidebar_subMenu"
              label="BOOKING"
            >
              <MenuItem
                // style={{padding:"0px"}}
                className="sidebar_subMenu_items"
                component={<Link to="/bookingTable" />}
              >
                <div className="sidebar_subMenu_subHeading">VIEW BOOKING</div>
              </MenuItem>
              <MenuItem
                className="sidebar_subMenu_items"
                component={<Link to="/newBooking" />}
              >
                <div className="sidebar_subMenu_subHeading">NEW BOOKING</div>
              </MenuItem>
            </SubMenu>
            <MenuItem
              className="sidebar_menuItem"
              component={<Link to="/addUser" />}
            >
              ADD USER
            </MenuItem>
            <SubMenu
              className="sidebar_subMenu"
              label="COMBO"
              open={openSubmenu === 'COMBO'}
              onClick={() => handleSubmenuClick('COMBO')}
            >
              <MenuItem
                className="sidebar_subMenu_items"
                component={<Link to="/viewCombo" />}
              >
                <div className="sidebar_subMenu_subHeading">VIEW COMBO</div>
              </MenuItem>
              <MenuItem
                className="sidebar_subMenu_items"
                component={<Link to="/addCombo" />}
              >
                <div className="sidebar_subMenu_subHeading">ADD COMBO</div>
              </MenuItem>
            </SubMenu>
            <MenuItem
              className="sidebar_menuItem"
              component={<Link to="/bookingVerification" />}
            >
              BOOKING VERIFICATION
            </MenuItem>
            <MenuItem
              component={<Link to="/notification" />}
              className="sidebar_menuItem"
            >
              NOTIFICATION
            </MenuItem>
            <MenuItem
              component={<Link to="/trending" />}
              className="sidebar_menuItem"
            >
              TRENDING{' '}
            </MenuItem>
            <MenuItem
              className="sidebar_menuItem--logout"
              component={<Link to="/" />}
              onClick={logout}
            >
              LOG OUT{' '}
            </MenuItem>
          </Menu>
        </Sidebar>
      </div>
    </div>
  )
}
