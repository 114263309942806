import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import InputField from './InputField'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { getOTP } from '../globalApi/Api'
import { useQuery } from 'react-query'
import axios from 'axios'

export const fetchPostsByUser = async (val) => {
  try {
    const response = await axios.get(
      `https://api.2kvirtualworld.com/WebUserManagement-0.0.1-SNAPSHOT/addusers/sendMail/${localStorage.getItem('emailId')}`,
      {
        params: { otp: val }, // Pass userId as a query parameter
      },
    )
    return response.data
  } catch (error) {
    throw new Error('Error fetching posts')
  }
}

const OtpEnter = () => {
  const { control, handleSubmit } = useForm()
  const navigate = useNavigate()

  const [seconds, setSeconds] = useState(30)
  const [isotp, setIsOtp] = useState('')
  const [isEnabled, setisEnabled] = useState(true)
  const [otpnumber, setOtpNumber] = useState()

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(timer)
          return 0
        }
        return prevSeconds - 1
      })
    }, 1000)
    return () => clearInterval(timer)
  }, [])

  // const { data, isLoading, isError, isRefetching } = useQuery('data', getOTP)
  const { data, isLoading, isError } = useQuery(
    ['posts', isotp],
    () => fetchPostsByUser(isotp),
    {
      enabled: isEnabled,
      onSuccess: (val) => {
        setisEnabled(false)
        console.log('otp send successfully..')
        console.log('otpEnter', val && val)
        setOtpNumber(val && val)
      },
      onerror: () => {
        setisEnabled(false)
        console.log('otp send failed..')
      },
    },
  )

  const onsubmit = (formData) => {
    setisEnabled(true)
    setIsOtp(formData?.otp)
    let navigates = `MAIL SENT :${formData?.otp}`
    if (otpnumber === navigates) {
      navigate('/reset')
    } else {
      console.log('page not navigate')
    }
  }
  return (
    <>
      <section className="main_Section">
        <div className="container">
          <div className="row justify-content-left align-items-center vh-100">
            <div className="col-lg-12 mx-5 d-flex  flex-column justify-content-center">
              <div className="otp_submit_form_block">
                <div className="treat_logo ">
                  <img
                    src="/assets/2k_logo.png"
                    className=""
                    style={{ width: '100%', height: '100%' }}
                  />
                </div>
                <form
                  className="d-flex flex-column form_block py-2"
                  onSubmit={handleSubmit(onsubmit)}>
                  <div>
                    <Controller
                      control={control}
                      name="otp"
                      render={(field) => (
                        <InputField
                          {...field}
                          loginInputCss="login_input_field"
                          placeholder="Enter Your OTP"
                          type="number"
                        />
                      )}
                      rules={{ required: true }}
                    />
                  </div>
                  {/* <p className="text-center text-danger otp_sec_text">
                    {seconds} seconds
                  </p> */}

                  <div className="text-center mt-5">
                    <button className="btn otp_submit_button">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default OtpEnter
